import React, {useState, useEffect} from 'react';
import {getInspectionFromId} from "../../services/inspectionService";

const InspectionItemPush = props => {
  const [plate, setPlate] = useState('');

  useEffect(() => {
    const { params } = props.match;

    

    const pushToInspectionStartForm  = (inspection_detail) => {
      props.history.push(
        {
          pathname: '/admin/inspection_start/',
          state: { 
            displayType: "edit" ,
            plate: inspection_detail.plate,
            place_name: inspection_detail.place_name,
            phase_name: inspection_detail.phase_name,
            make: inspection_detail.make,
            model: inspection_detail.model,
            vm: inspection_detail.model_year,
            mileage: inspection_detail.mileage,
            id: inspection_detail.id,
            info: inspection_detail.info,
          }
        }
      )
    }

    
    // localStorage.setItem('currentInspectionId', props.match.params.id);
    // console.log("Start getInspectionFromId", props.match.params.id)
    getInspectionFromId(props.match.params.id, "all").then((response) => {
      console.log("getInspectionFromId response", response)
      if (response.data.data) {
        // console.log("getInspectionFromId attributes", response.data.data[0].attributes)
        pushToInspectionStartForm(response.data.data[0].attributes)
      }
    });  
  }, [props]);




  return (
    <div></div>
  );
};

export default InspectionItemPush;