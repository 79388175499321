import React, { useState } from 'react';
import {ToggleButton , ToggleButtonGroup} from 'react-bootstrap'

const InputCheckbox = props => {
  const [radioValue, setRadioValue] = useState({
    id: props.item.inspection_result.data.id,
    value: props.item.inspection_result.data.attributes.value,
    reason: props.item.inspection_result.data.attributes.value_text
  });


  const radios =  props.item.inspection_options.map((i) => { return { name: i, value: i } } )
  

  const handleChange = (e) => {
    // console.log("handleChange radio",e)
    setRadioValue({ ...radioValue, value: e });
    props.setIsInputChange(true)
    props.setFormResults({ ...props.formResults, [props.item.inspection_result.data.id]: e });
  };


  return (
    <>
      <ToggleButtonGroup 
      type="checkbox" value={radioValue.value} 
      name={props.item.inspection_result.data.id} 
      onChange={handleChange} 
      className="row mb-2 mt-2">
        {radios.map((radio, idx) => (
            <ToggleButton
              key={idx}
              type="checkbox"
              variant="light"
              value={radio.value}
              checked={radioValue.value === radio.value}
              className="input-checkbox col-12  d-flex justify-content-start align-items-center"
              disabled={props.item.only_self_page}
            >
              &nbsp;&nbsp;{radio.name}
            </ToggleButton>
          ))}
    </ToggleButtonGroup>
    </>
  );
};



export default InputCheckbox;