import React from "react";
import { FaCheck } from "react-icons/fa";

function DimensionsModal(props) {
  return (
    <div
      className="modal fade"
      id="dimensionsModal"
      tabIndex="-1"
      aria-labelledby="dimensionsModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="my-5 d-flex flex-column align-items-center justify-content-center ">
              <h1 className="fs-4 text-primary">Tallennus onnistui</h1>
              <div className="text-primary fs-1">
                <FaCheck />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DimensionsModal;
