import React, { Component, Fragment} from 'react';
import {Text, View, Image} from '@react-pdf/renderer';
import pdfStyles from './pdfStyle';
import {getInspectionResult} from "../../services/inspectionService"

class InspectionPdfImages extends Component {
  constructor(props) {
    super(props);

  }

  render() {
    const groupHeader = (
      <View style={pdfStyles.imageGroupName}>
        <Text>{this.props.groupName}</Text>
      </View>
    )
    let items = []
    try {
      const props_items = this.props.items
      items = props_items.map((i)=>{
        let images = ''
        const kind = i.attributes.inspection_result.data.attributes.kind
        if (kind.endsWith("images")) {
          images = i.attributes.inspection_result.data.attributes.attach_image_urls
        }
        return {
          name: i.attributes.name, 
          images: images, 
        }
      })
    } catch (error) {
      console.log("InspectionPdfGroup assign error", error);
      console.log("error item", this.props.items);
    }
    const imageItems = items.filter((i) => i.images !== '');
    const rows = imageItems.map((i, idx) => (
        <View wrap={false}  style={pdfStyles.imageItemRow} key={idx}>
          <Text style={pdfStyles.imageItemName}>{this.props.groupName}: {i.name}</Text>
          <View style={pdfStyles.image_conatainer} wrap={false} >
            {i.images.map((image,idx_img) => (
              <View key={`view${idx_img}`}>
                <Text ></Text>
                <Image key={`image_${idx_img}`} style={pdfStyles.attach_image}  src={image.url}  />
              </View>
              ))}
          </View>
        </View>
    ))
    if (imageItems.length > 0) {
      return (<Fragment><View wrap={false} style={pdfStyles.imageGroupTableView}>{rows}</View></Fragment>);
    } else {
      return null;
    }
  }
}

export default InspectionPdfImages;