import {postFormDataRequest, getRequest, updateRequest} from "./httpServices";
import config from "../config";
import auth from "./authService";

const mainUrl = config.easyad_api_url;

export function inspectionUrl(plate, place, status, start, length, date_from = null, date_to = null) {
  let url = `${mainUrl}/api/v1/inspection/index_draft?start=${start}&length=${length}`;
  if (plate) {
    url = `${url}&plate=${plate.toUpperCase()}`;
  } 
  if (place) {
    url = `${url}&place=${place.toUpperCase()}`;
  }
  if (status) {
    url = `${url}&status=${status}`;
  }
  if (date_from && date_to) {
    url = `${url}&from=${date_from}&to=${date_to}`;
  }
  return url 
}

export function inspectionIdUrl(id) {
  return `${mainUrl}/api/v1/inspection?id=${id}`;
}

export function placeUrl() {
  return `${mainUrl}/api/v1/inspection/places`;
}

export function uploadFileUrl() {
  return `${mainUrl}/api/v1/inspection/save_image`;
}

export function phaseUrl(office_id = null) {
  return `${mainUrl}/api/v1/inspection/places`;
}

export function checkPlateUrl(plate) {
  return `${mainUrl}/api/v1/vehicle_datum/by_plate?plate=${plate}`;
}

export function createUrl() {
  return `${mainUrl}/api/v1/inspection`;
}

export function createSaveResultUrl() {
  return `${mainUrl}/api/v1/inspection/save_result_detail`;
}

export function createEditUrl(id) {
  return `${mainUrl}/api/v1/inspection/${id}`;
}


export function createDeleteUrl(id) {
  return `${mainUrl}/api/v1/inspection/${id}`;
}

function inspectionResultUrl(id) {
  return `${mainUrl}/api/v1/inspection/inspection_result?id=${id}`;
}

function inspectionImageDeleteUrl(id) {
  return `${mainUrl}/api/v1/inspection/delete_image?id=${id}`;
}

function getOrCreateGalleryUrl(id) {
  return `${mainUrl}/api/v1/inspection_gallery/get_or_create_by_inspection/${id}`;
}

function getParentInfoByGalleryUrl(id) {
  return `${mainUrl}/api/v1/inspection_gallery/get_parent_info_by_gallery/${id}`;
}

export const apiRequest = async (url, method = 'GET', body = '') => {
  let myHeaders = new Headers();
  let json_body = (body) ? JSON.stringify(body) : ''
  const token = auth.getToken();
  myHeaders.append("Authorization", "Token " + token);
  let request_data = {}
  if (body === '') {
    request_data = {
      method: method,
      headers: myHeaders,
    };
  } else {
    myHeaders.append("Content-Type", "application/json");
    request_data = {
        method: method,
        headers: myHeaders,
        body: json_body
      };
  }
  try {
    let response = await fetch(url,request_data);
    let json = await response.json();
    return json.data;
  } catch (error) {
    console.error("apiRequest error", error);
  }
};

const sleep = (milliseconds) => {
  return new Promise(resolve => setTimeout(resolve, milliseconds))
}

export function getCurrentInspection() {
  let inspection = null
  try {
    inspection = localStorage.getItem('currentInspection');
  } catch (error) {
    console.error("getCurrentInspection error", error);
  } finally {
    return inspection
  }
}

export  const changeResults = (allResults, id, value) => {
  const changed_results = {...allResults, [id]: value}
  changed_results[id] = value
  return changed_results
}

export  const changeInspectionAllResults = (inspectionStr, groupNumber, resultId, value) => {
  let json = JSON.parse(inspectionStr);
  if (json.attributes.inspection_groups.data[groupNumber]) {
    let items = json.attributes.inspection_groups.data[groupNumber].attributes.inspection_items.data;
    for (let key in items) {
      let item = items[key]
      if ( item.attributes.inspection_result.data.id === resultId )  {
        json.attributes.inspection_groups.data[groupNumber].attributes.inspection_items.data[key].attributes.inspection_result.data.attributes.value = value
      }    
      if (resultId.startsWith("reason")) {
        const new_id = resultId.replace('reason','')
        if ( item.attributes.inspection_result.data.id === new_id )  {
          json.attributes.inspection_groups.data[groupNumber].attributes.inspection_items.data[key].attributes.inspection_result.data.attributes.value_text = value
        } 
      }
    }
    return JSON.stringify(json)
  } else {
    return inspectionStr
  }
}

export function uploadFileApi(file, results_id, is_gallery = false) {
  const formData = new FormData();
  formData.append('file',file)
  formData.append('results_id',results_id)
  formData.append('is_gallery',is_gallery)
  const url = uploadFileUrl()
  return postFormDataRequest(url, formData)
}



export async function getPlaces() {
  let params = {}
  let url = placeUrl()
  let result = await getRequest(url, params);
  return result;
}

export async function getInspections(plate, place, status, start, length, date_from = null, date_to = null) {
  let params = {}
  let url =  inspectionUrl(plate, place, status, start, length, date_from, date_to)
  let result = await getRequest(url, params);
  return result;
}

export async function getCarInfo(plate) {
  let params = {}
  let url = checkPlateUrl(plate)
  let result = await getRequest(url, params);
  return result;
}

export async function getInspectionResult(id) {
  let params = {}
  let url = inspectionResultUrl(id)
  let result = await getRequest(url, params);
  return result;
}

export async function deleteInspectionImage(id) {
  let params = {}
  let url = inspectionImageDeleteUrl(id)
  let result = await getRequest(url, params);
  return result;
}

export async function getGalleryIdFormInspection(id) {
  let params = {}
  let url = getOrCreateGalleryUrl(id)
  let result = await getRequest(url, params);
  return result;
}

export async function saveInspection(id, params) {
  let url = createEditUrl(id)
  let result = await updateRequest(url, params);
  return result;
}

export async function getInspectionFromId(id) {
  let url = inspectionIdUrl(id)
  let result = await getRequest(url);
  return result;
}

export async function getParentInfoByGallery(id) {
  let url =  getParentInfoByGalleryUrl(id);
  let result = await getRequest(url);
  return result;
}

export async function saveTabStep(name, value) {
  localStorage.setItem(name, value);
}

export function getTabStep(name) {
  let result =  localStorage.getItem(name) 
  return result ? result : null
}

export async function getInspectionsDetails(ids){
  let url =  `${mainUrl}/api/v1/inspections_details?ids=${ids}`;
  let result = await getRequest(url);
  return result;
} 
export default {
  inspectionUrl,
};
