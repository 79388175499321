import React from 'react';
import { FaImages, FaAsterisk } from "react-icons/fa";

const InputAttachFile = props => {
  return (
    <>
      <button
          type="button"
          className="btn btn-sm"
          data-bs-toggle="modal"
          data-bs-target={`#dropZoneModal${props.item.inspection_result.data.id}`}
          // data-result-id={props.item.inspection_result.data.id}
        >
            {['both','image'].includes(props.item.inspection_result.data.attributes.forced) 
            && <span className="fs-12 me-1" style={{color: 'red'}}><FaAsterisk /></span>}
            <span className="fs-12 me-1">
              <FaImages />
            </span>
            <span className="me-1">Lisää kuvaa</span>
        </button>
        {/* <button
          type="button"
          className="btn btn-sm"
          onClick={()=> window.open(`http://localhost:3011/inspections/view_images/${props.item.inspection_result.data.id}`, 'view_image', 'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no, height=600, width=900')}
        >
          <span className="fs-12 me-1">
              <FaEye />
            </span>
            <span className="me-1">View images</span>
        </button>             */}
    </>
  );
};



export default InputAttachFile;