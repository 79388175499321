import React, { Component } from 'react';
import PropTypes from 'prop-types';
import imageEmpty from "../../images/empty.png";
import dimensionSedan from "../../images/dimension_sedan.jpg";
import dimensionVan from "../../images/dimension_van.jpg";
import dimensionWagon from "../../images/dimension_wagon.jpg";
import {FaHandPointUp} from "react-icons/fa";


export default class DimensionSimilarModel extends Component {
  render() {
    const body_type  = this.props.body_type
    const display_text  = this.props.display_text
    const value  = this.props.value

    let imgBodyType = (body_type) => {
      // wagon, van, sedan
      switch(body_type) {
        case "sedan":   return <img className="img-fluid" src={dimensionSedan} alt="Sedan" style={{width: "50px" }} />;
        case "van":   return <img className="img-fluid" src={dimensionVan} alt="Van" style={{width: "50px" }} />;
        case "wagon":   return <img className="img-fluid" src={dimensionWagon} alt="Wagon" style={{width: "50px" }}/>;
        default:      return <img className="img-fluid" src={imageEmpty} alt="Sedan" style={{width: "50px" }}/>
      }
    }

    return (

      <div 
        className="car-model-box d-flex  bg-light bd-highlight mt-3 example-parent" 
        style={{height: '60px'}}
        >
        <button
          value={value}
          className="btn border-none no-shadow-btn outline-none"
          onClick={this.props.onClick}
          >
          <FaHandPointUp/> Valitse tämä mittaus
        </button>
        <div className="align-self-center p-2">{imgBodyType(body_type)}</div>
        <div className="align-self-center p-2">{display_text}</div>
    
      </div>
    );
  }
}

DimensionSimilarModel.propTypes = {
  onClick: PropTypes.func
};