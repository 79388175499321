import React, {useState, useEffect} from 'react';
import {getDimensions} from "../../services/dimensionService";

const DimensionItem = props => {
  const [plate, setPlate] = useState('');

  useEffect(() => {
    const { params } = props.match;
    setPlate(params.plate);

  
    const q_params = new URLSearchParams(props.location.search);
    const inspection = q_params.get('inspection');

    const pushToDimensionForm  = (dimension) => {
      props.history.push(
        {
          pathname: '/admin/dimension_form/',
          state: { 
            id: dimension.id, 
            formatted: dimension.formatted,
            inspection: inspection
          }
        }
      )
    }

    const pushToNew  = () => {
      props.history.push(
        {
          pathname: '/admin/dimension_form/',
          state: { 
            display: 'new',
            inspection: inspection,
            new_plate: props.match.params.plate,
          }
        }
      )
    }


    getDimensions(props.match.params.plate, "all").then((response) => {
      if (response && response.length > 0) {
        pushToDimensionForm(response[0])
      } else {
        pushToNew()
      }
    });  
  }, [props]);




  return (
    <div>
      hello {plate}
    </div>
  );
};

export default DimensionItem;