import React from "react";

function SalesImageModal({ imgUrl }) {
  return (
    <div
      className="modal fade"
      id="salesImageModal"
      tabIndex="-1"
      aria-labelledby="salesImageModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            {imgUrl ? (
              <img
                src={imgUrl}
                alt="Myyntikuvat"
                className="img-fluid rounded"
              />
            ) : (
              <div
                className="w-100 d-flex align-items-center justify-content-center"
                style={{ minHeight: "240px" }}
              >
                <div
                  class="spinner-grow bg-primary"
                  style={{ width: "5rem", height: "5rem" }}
                  role="status"
                >
                  <span class="visually-hidden">Ladataan...</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SalesImageModal;
