import React from "react";
import { Link } from "react-router-dom";
import auth from "../../services/authService";

const AdminNavLinks = props => {
  const currentUser = auth.getCurrentToken();
  const user = currentUser ? currentUser.user : null;
  const fullname = user ? user.fullname : null;
const adminNavNames = props.adminNavNames
  const handleClick = () => {
    localStorage.clear();
    auth.logout();
    window.location = "/";
  };
  const current_location = window.location.href.split("/")[4] ? window.location.href.split("/")[4].substring(0,5) : 'xxx'
  return (
    <>
      <div className="w-100 max-width-540 d-flex align-items-center justify-content-between mx-3 hide-on-mobile-nav">
        {adminNavNames.map((item, index) => {
          let is_active = item.url.startsWith(current_location) ? 'active' : '';
          return (<Link
            to={`/admin/${item.url}`}
            key={item.label}
            className={`navbar-brand m-0 d-flex align-items-center fs-14 position-relative text-dark ${is_active}`}
          >
            {item.label}
          </Link>)
        })}
      </div>

      {user && (
        <div className="d-flex align-items-center hide-on-mobile-nav">
          <Link
            to="/admin/user"
            className="btn btn-primary text-white fs-14 rounded-pill me-2 text-nowrap"
          >
            <span className="me-1">{fullname}</span>
          </Link>
          <button
            onClick={handleClick}
            className="btn btn-primary text-white fs-14 rounded-pill me-2 text-nowrap"
          >
            Kirjaudu ulos
          </button>
        </div>
      )}
    </>
  );
};

export default AdminNavLinks;
