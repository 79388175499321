import React, { Component } from 'react';
import {ScaleLoader} from "react-spinners";
import {getDimensions} from "../../services/dimensionService";
import DimensionTable from './DimensionTable';
import DimensionSelector from './DimensionSelector';

class Dimension extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dimensions: [],
      loading: true,
    }
  }

  componentDidMount() {
    getDimensions().then((response) => {
      this.updateDimensions(
          response,
          {aa: 1}
          );
    });
  }



  updateDimensions = (data, search_params) => {
    this.setState({
      dimensions: data,
      loading: false,
    });
  }

  beginFetch = () => {
    this.setState({loading: true})
  }

  render() {
    return (
      <main>
        <div className="container">
          <h1 className="fs-3 text-primary my-3">Mitat</h1>
          <div className="w-100 border rounded-3 p-3 mt-3">
            <DimensionSelector updateDimensions={this.updateDimensions} beginFetch={this.beginFetch}/>
          </div>
          <div className="w-100 my-3 p-3 shadow rounded">
            {this.state.loading ?
                <div className={'w-100 text-center'}><ScaleLoader color={'blue'} loading={this.state.loading} css={''} size={150}/></div> :
                <DimensionTable dimensions={this.state.dimensions} />
            }
          </div>
          
        </div>
      </main>
    );
  }
}

export default Dimension;