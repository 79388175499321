import React, { Component } from "react";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import InspectionPdfMain from "./InspectionPdfMain";
import { Card } from "react-bootstrap";
import { FaSave, FaArrowLeft, FaFilePdf } from "react-icons/fa";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { parseISOString, isoFormatDMY } from "../../services/formatService";
import { saveInspection, getInspectionFromId } from "../../services/inspectionService";
import { SyncLoader } from "react-spinners";
import {Link} from "react-router-dom";

class InspectionSummary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: "",
      info: "",
      make: "",
      mileage: null,
      model: "",
      model_year: null,
      phase_id: "",
      phase_name: "",
      phase_step: null,
      place_id: "",
      place_locality: "",
      place_name: "",
      plate: "",
      status: "",
      created_at: "",
      updated_at: "",
      isComplete: false,
      inspection: {},
      isSaving: false,
      attach_pdf_url: null,
    };
  }

  componentDidMount() {
    // Do this componentDidMount only first time
    const inspectionAll = this.props.inspectionAll;
    this.tryParseJson(inspectionAll);
    this.init();
  }

  init() {}

  componentDidUpdate(prevProps) {
    // Do this componentDidUpdate only props change
    if (prevProps.inspectionAll !== this.props.inspectionAll) {
      console.log("componentDidUpdate only props change");
      this.tryParseJson(this.props.inspectionAll);
    }
  }

  getLastestInspection = (id) => {
    getInspectionFromId(id).then((result) => {
      console.log("getLastestInspection result", result)
      this.setState({
        inspection: result.data.data[0].attributes,
      })
    })
  }

  tryParseJson = (str) => {
    try {
      let json = JSON.parse(str);
      const info = json.attributes;
      this.getLastestInspection(info.id);
      this.setState({
        id: info.id,
        // inspection: info,
        plate: info.plate,
        make: info.make,
        mileage: info.mileage,
        model: info.model,
        model_year: info.model_year,
        phase_name: info.phase_name,
        place_name: info.place_name,
        info: info.info,
        updated_at: info.updated_at,
        phase_step: info.phase_step,
        isComplete: ["accepted", "waiting_for_acceptance"].includes(info.status),
        attach_pdf_url: info.attach_pdf_url
      });
    } catch (error) {
      console.log("tryParseJson error", error);
    }
  };

  handleCheckComplete = (event) => {
    this.setState({ isComplete: event.target.checked });
  };

  handleBack = () => {
    this.props.changePage(this.props.page - 1);
  };


  handleSaveInspection = () => {
    this.setState({ isSaving: true });
    console.log("handleSaveInspection", this.state.id, this.state.isComplete);
    if (this.state.isComplete === true) {
      let body = {
        inspection: {
          status: "waiting_for_acceptance",
        },
      };
      saveInspection(this.state.id, body).then((result) => {
        console.log("saveInspection result-----", result);
        this.setState({ attach_pdf_url: result.data.data.attributes.attach_pdf_url });
        this.setState({ isSaving: false });
      });
    } else {
      this.setState({ isSaving: false });
    }
  };

  render() {
    return (
      <div>
        <div>
          <Card className="mt-3">
            <Card.Header>
              Yhteenveto ({this.props.page + 1}/{this.props.page + 1})
            </Card.Header>
            <Card.Body>
              <div>
                {this.state.plate && `${this.state.plate} `} 
                {this.state.make && `${this.state.make} `} 
                {this.state.model && `${this.state.model} `} 
                {this.state.model_year && `${this.state.model_year} `} 
                {this.state.mileage && `${this.state.mileage} km`} 
              </div>
              <div>
                {this.state.phase_step} {this.state.phase_name} /{" "}
                {this.state.place_name}
              </div>
              <div>
                Tarkastaja / Merkintä: {this.state.info} Päivitä:
                {isoFormatDMY(parseISOString(this.state.updated_at))}
              </div>
              <div className="text-center">
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={this.handleCheckComplete}
                      checked={this.state.isComplete}
                      name="checkedA"
                      disabled={this.props.errorSteps.length > 0}
                    />
                  }
                  label="Tarkastus valmis"
                />
              </div>
            </Card.Body>
            <Card.Footer className="text-center">
              <button
                className="btn btn-primary text-white fs-14 rounded-pill me-2 text-nowrap"
                onClick={() => this.handleBack()}
              >
                <span className="fs-12 me-1">
                  <FaArrowLeft />
                </span>
                <span className="ms-1">Edellinen</span>
              </button>
              <button
                disabled={this.props.errorSteps.length > 0}
                className="btn btn-primary text-white fs-14 rounded-pill me-2 text-nowrap"
                onClick={() => this.handleSaveInspection()}
              >
                {this.state.isSaving 
                ? (
                  <>
                    <SyncLoader color='#ffffff'/>
                  </>
                )
                : (
                  <>
                    <span className="fs-12 me-1"><FaSave /></span>
                    <span className="me-1">Tallenna Ja luo pdf</span>
                  </>
                )}
              </button>
            </Card.Footer>
          </Card>
        </div>
        <div className="mt-2">
          {/* <h5>Yhteenveto Pdf</h5> */}
          {this.state.isSaving 
          ? (<SyncLoader/>)
          : this.state.attach_pdf_url && (
            <Link to={{ pathname: this.state.attach_pdf_url }} target="_blank" >    
                <button
                  className="btn btn-primary text-white fs-14 rounded-pill me-2 text-nowrap"
                  >
                  <FaFilePdf />
                  <span className="me-1">Download pdf</span>
                </button>
          </Link>
          ) }
          {/* <PDFViewer className={"w-100 height-100vh hide-on-mobile-nav"}>
            <InspectionPdfMain
              inspection={this.state.inspection}
              inspectionString={this.props.inspectionAll}
            />
          </PDFViewer> */}

          {/* <PDFDownloadLink
            document={
              <InspectionPdfMain
                inspection={this.state.inspection}
                inspectionString={this.props.inspectionAll}
              />
            }
            fileName={ this.state.plate ? `tarkastus_${this.state.plate}.pdf` : 'tarkastus.pdf' }
          >
            {({ blob, url, loading, error }) =>
              loading ? (
                "Loading document..."
              ) : (
                <button className="btn btn-primary text-white fs-14 rounded-pill me-2 text-nowrap hide-on-non-mobile-nav">
                  <span className="fs-12 me-1">
                    <FaFilePdf />
                  </span>
                  <span className="me-1">Download Pdf</span>
                </button>
              )
            }
          </PDFDownloadLink> */}


        </div>
      </div>
    );
  }
}

export default InspectionSummary;
