import React from "react";
import { Link } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa";
import SubNavs from "./SubNavs";
import auth from "../../services/authService";

const BasicNavLinks = ({ navItems, navSubItems, isOpen, setIsOpen }) => {

  const currentUser = auth.getCurrentUser();
  const user = currentUser ? currentUser.user : null;
  const fullname = user ? user.fullname : null;

  const handleClick = () => {
    auth.logout();
    window.location = "/";
  };

  return (
    <>
      <div className="w-100 max-width-750 d-flex align-items-center justify-content-between mx-3 hide-on-mobile-nav">
        {navItems.map((item, index) => (
          <div
            key={item}
            className="navbar-brand m-0 d-flex align-items-center fs-14 position-relative cursor-pointer"
            onClick={() => setIsOpen(!isOpen)}
          >
            <span className="me-1 text-dark">{item}</span>
            {index < 3 && (
              <span className="fs-12 fw-lighter text-primary">
                <FaArrowRight />
              </span>
            )}

            {index < 3 && isOpen && navSubItems[item] && (
              <SubNavs navItem={navSubItems[item]} />
            )}
          </div>
        ))}
      </div>

      {user && (
        <div className="d-flex align-items-center hide-on-mobile-nav">
          <Link
            to="/"
            className="btn btn-primary text-white fs-14 rounded-pill me-2 text-nowrap"
          >
            <span className="me-1">{fullname}</span>
          </Link>
          <button
            onClick={handleClick}
            className="btn btn-primary text-white fs-14 rounded-pill me-2 text-nowrap"
          >
            <span className="me-1">Kirjaudu ulos</span>
            <span className="fs-12">
              <FaArrowRight />
            </span>
          </button>
        </div>
      )}

      {/* <div className="d-flex align-items-center hide-on-mobile-nav">
        <Link
          to="/login"
          className="btn btn-primary text-white fs-14 rounded-pill me-2 text-nowrap"
        >
          <span className="me-1">Kirjaudu ulos</span>
          <span className="fs-12">
            <FaArrowRight />
          </span>
        </Link>
      </div> */}
    </>
  );
};

export default BasicNavLinks;
