import React from "react";
import AdminDrawerLinks from "./AdminDrawerLinks";

const NavDrawer = ({
  drawerOpen,
  setDrawerOpen,
  navItems,
  navSubItems,
  isAdminPage,
  adminNavNames,
}) => {
  const drawerClases =
    "position-fixed top-0 start-0 height-100 z-index-to-1200 d-flex";
  return (
    <div
      className={
        drawerOpen
          ? `${drawerClases} drawer-show d-lg-none d-xl-none d-xxl-none`
          : `${drawerClases} drawer-hide`
      }
    >
      {isAdminPage ? (
        <AdminDrawerLinks
          drawerOpen={drawerOpen}
          adminNavNames={adminNavNames}
          setDrawerOpen={setDrawerOpen}
        />
      ) : null}
      <div
        className="w-100 height-100 bg-dark-transparent"
        onClick={() => setDrawerOpen(!drawerOpen)}
      ></div>
    </div>
  );
};

export default NavDrawer;
