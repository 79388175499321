import React from "react";
import { Switch, useLocation } from "react-router-dom";
import Home from "./pages/Home";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Inspection from "./components/inspections/Inspection";
import InspectionDetail from "./components/inspections/InspectionDetail";
import InspectionStart from "./components/inspections/InspectionStart";
import InspectionItemPush from "./components/inspections/InspectionItemPush";
import Login from "./pages/Login";
import ProtectedRoute from "./components/ProtectedRoute";
import UserProfile from "./pages/UserProfile";
import Dimension from "./components/dimensions/Dimension";
import DimensionForm from "./components/dimensions/DimensionForm";
import DimensionItem from "./components/dimensions/DimensionItem";
import InspectionGallery from "./pages/InspectionGallery";
import GalleryItem from "./components/galleries/GalleryItem";
import SalesGalleryItem from "./components/galleries/SalesGalleryItem";
import SalesImagesMain from "./components/sales_images/SalesImagesMain";

function App() {
  const { pathname } = useLocation();

  return (
    <>
      <Navbar pathname={pathname} />
      <Switch>
        <ProtectedRoute path="/admin/dimensions" component={Dimension} />
        <ProtectedRoute
          path="/admin/dimension_form"
          component={DimensionForm}
        />
        <ProtectedRoute
          path="/admin/dimension/:plate"
          component={DimensionItem}
        />
        <ProtectedRoute 
          path="/admin/inspections" 
          component={Inspection} 
        />
        <ProtectedRoute
          path="/admin/inspection_form"
          component={InspectionDetail}
        />
        <ProtectedRoute
          path="/admin/inspection_start"
          component={InspectionStart}
        />
        <ProtectedRoute
          path="/admin/inspection/:id"
          component={InspectionItemPush}
        />
        <ProtectedRoute
          path="/admin/cosmetic-remarks/:id"
          component={GalleryItem}
        />
        <ProtectedRoute
          path="/admin/cosmetic-remarks"
          component={InspectionGallery}
        />
        <ProtectedRoute path="/admin/salesimages" component={SalesImagesMain} />
        <ProtectedRoute
          path="/admin/salesimage/:id"
          component={SalesGalleryItem}
        />
        <ProtectedRoute path="/admin/user" component={UserProfile} />
        <ProtectedRoute path="/admin" component={Inspection} />
        <ProtectedRoute path="/home" component={Home} />
      </Switch>
      <Login />
      <Footer />
    </>
  );
}

export default App;
