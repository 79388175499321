import React, { Component } from 'react';
import { Page, Text, View, Document, StyleSheet, Image, Font } from '@react-pdf/renderer';
import dimensionSedan from "../../images/dimension_sedan.jpg";
import InspectionPdfGroup from "./InspectionPdfGroup";
import InspectionPdfImages from "./InspectionPdfImages";
import pdfStyles from './pdfStyle';

class InspectionPdfMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inspection: null,
    }
  }

  componentDidMount() {
    // Do this componentDidMount only first time
    this.setInspectionValues();
    this.init()
  }

  init() {
  }

  componentDidUpdate(prevProps) {
    // Do this componentDidUpdate only props change
    if (prevProps.inspection !== this.props.inspection) {
      console.log("componentDidUpdate InspectionPdfMain only props change")
      this.setInspectionValues();
    }
  }

  setInspectionValues() {
    console.log("componentDidUpdate setInspectionValues inspection", this.props.inspection)
    this.setState({inspection: this.props.inspection})
  }

  render() {
    const {inspection} = this.state; 
    console.log("render inspection pdf inspection",inspection)
    let return_value = (
      <Document>
              <Page orientation="landscape" size="A4" style={pdfStyles.page}>
                <View style={pdfStyles.tablePageHeader} fixed>
                  <Text style={pdfStyles.pageHeaderLeft}>
                    -</Text>
                  <Text style={pdfStyles.pageHeaderRight}>
                    -</Text>
                </View>
              <Text style={pdfStyles.pageNumber} render={({ pageNumber, totalPages }) => (
                `${pageNumber} / ${totalPages}`
                )} fixed />
            </Page>
      </Document>
    );
    if (inspection) {
      if (inspection!=={}) {
      try {
        let inspection_groups = inspection.inspection_groups.data
        // console.log("test",JSON.stringify(inspection_groups)) 

        Font.register({
          family: 'Oswald',
          src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
        });
        
        return_value = (
          <Document>
              <Page style={pdfStyles.page}>
                <View  style={pdfStyles.headerContainer}  fixed>
                  <Text style={pdfStyles.headerLeft} >
                    {this.state.inspection.phase_step} {this.state.inspection.phase_name} / {this.state.inspection.place_name}
                  </Text>
                  <Text style={pdfStyles.headerRight} >
                    {this.state.inspection.plate} {this.state.inspection.make} {this.state.inspection.model} {this.state.inspection.mileage}km
                  </Text>
                </View>
                {inspection_groups.map((g, idx) => (
                      <InspectionPdfGroup key={idx} groupName={g.attributes.name} items={g.attributes.inspection_items.data}/>
                    ))}
                <Text style={pdfStyles.text} break>
                  Kuvat
                </Text>
                {inspection_groups.map((g, idx) => (
                      <InspectionPdfImages key={idx} groupName={g.attributes.name} items={g.attributes.inspection_items.data}/>
                    ))}
              
                <Text style={pdfStyles.pageNumber} render={({ pageNumber, totalPages }) => (
                  `${pageNumber} / ${totalPages}`
                )} fixed />
              </Page>
            </Document>
        );

      } catch (error) {
        console.log("inspection parse error", error);
      }
    }} else {
      console.log("No inspection")
    }
    return return_value;
  }
}



export default InspectionPdfMain;