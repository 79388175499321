import {postRequest, updateRequest, getRequest, deleteRequest} from "./httpServices";
import config from "../config";
import auth from "./authService";

const mainUrl = config.easyad_api_url;
const currentUser = auth.getCurrentUser();
const email = currentUser ? currentUser.email : null;

export function dimensionUrl(plate, status_option, start , length) {
  // console.log("plate",plate);
  let url = `${mainUrl}/api/v1/inspection_dimension?status_option=${status_option}`;
  if (length > 0) {
    url += `&start=${start}&length=${length}`;
  }
  if (plate) {
    url = `${url}&plate=${plate.toUpperCase()}`;
  } 
  return url 
}

export function dimensionNameUrl(name) {
  let url = `${mainUrl}/api/v1/inspection_dimension/dimension_names?name=${name}`;
  return url 
}

function updateUrl(id) {
  return `${mainUrl}/api/v1/inspection_dimension/${id}`;
}

function createUrl() {
  return `${mainUrl}/api/v1/inspection_dimension`;
}

function createSimilarModelsUrl() {
  return `${mainUrl}/api/v1/inspection_dimension/similar`;
}


export async function getDimensions(plate = null,  status_option, start = 0 , length = 0) {
  let params = {}
  let url = dimensionUrl(plate, status_option, start , length)
  let result = await getRequest(url, params);
  return formatDimensionsResult(result);
}

export async function getDimensionNames(name) {
  let params = {}
  let url = dimensionNameUrl(name)
  let result = await getRequest(url, params);
  return result;
}

export async function createDimensions(dimensionParams) {
  let createParams = Object.assign(dimensionParams, {user_email: email});
  let params = {inspection_dimension: createParams}
  let url = createUrl()
  let result = await postRequest(url, params);
  return result;
}

export async function saveDimensions(id, dimensionParams) {
  let params = {inspection_dimension: dimensionParams}
  let url = updateUrl(id)
  let result = await updateRequest(url, params);
  return result;
}

export async function getSimilarModels(make, model, year, body_type_id,  plate = "") {
  let params = {
    make: make,
    model: model,
    year: year,
    body_type_id: body_type_id,
    plate: plate,
  }
  let result = await getRequest(createSimilarModelsUrl(), params);
  return result;
}

export async function deleteDimensions(id) {
  let url = updateUrl(id)
  let result = await deleteRequest(url);
  return result;
}

function formatDimensionsResult(result) {
  let datas = result.data?.data
  for(let i = 0; i < datas.length; i++){
    datas[i].formatted = formatDimensionData(datas[i]);
  }
  return datas;
}

function formatDimensionData(dimension_data) {
  let t = dimension_data.attributes
  // console.log(t)
  // :id, :plate, :body_type, :description, :a, :b, :c, :d, :e, :f, :g, :h, :i, :j
  return {
    id: t.id,
    plate: t.plate,
    description: t.description,
    body_type: t.body_type,
    a: t.a,
    b: t.b,
    c: t.c,
    d: t.d,
    e: t.e,
    f: t.f,
    g: t.g,
    h: t.h,
    i: t.i,
    j: t.j,
    status: t.status,
    user_email: t.user_email
  }
}

export default {
  getDimensions,
};