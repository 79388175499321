import React , {useState} from 'react';
import {getFormatTimestamp, dateISOToString1} from '../../services/formatService'

const InputDateTime = props => {
  const [textValue, setTextValue] = useState({
    id: props.item.inspection_result.data.id,
    value: props.item.inspection_result.data.attributes.value,
    timeValue: props.item.inspection_result.data.attributes.value ? dateISOToString1(props.item.inspection_result.data.attributes.value) : null
  });

  const handleChange = (e) => {
    // const timestamps = getFormatTimestamp(e.target.value)
    setTextValue({ ...textValue, timeValue: e.target.value });
    props.setIsInputChange(true);
    props.setFormResults({ ...props.formResults, [e.target.name]: e.target.value + ':00.000Z' });
  };

  const notValidStyle = {borderColor: 'red', borderWidth: 1}

  return (
    <>
      <input
        type="datetime-local"
        className="form-control no-shadow-btn mb-1"
        value={textValue.timeValue}
        name={props.item.inspection_result.data.id}
        onChange={handleChange}
        style={props.mandatoryInputs.includes(props.item.inspection_result.data.id) ? notValidStyle : null}
        disabled={props.item.only_self_page}
      />
    </>
  );
};


export default InputDateTime;