import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";
import NavDrawer from "./navbar/NavDrawer";
import BasicNavLinks from "./navbar/BasicNavLinks";
import AdminNavLinks from "./navbar/AdminNavLinks";
import logo_black from "../images/autollelogo.png";

export default function Navbar({ pathname }) {
  const [isAdminPage, setIsAdminPage] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);

  useEffect(() => {
    let route = pathname.split("/").indexOf("admin") >= 0;
    setIsAdminPage(route);
  }, [pathname]);

  const navItems0 = [];
  const navItems = [
    "Tarkastukseen tulevat",
    "Tarkistustyökalun määritys",
    "Virhekuvat",
  ];

  const navSubItems = {
    "Tarkistustyökalun määritys": [
      { label: "Toimipisteet", url: "/admin" },
      { label: "Vaiheet", url: "/admin" },
      { label: "Ryhmät", url: "/admin" },
      { label: "Kohdat", url: "/admin" },
      { label: "Valinnat", url: "/admin" },
    ],
  };
  const adminNavNames = []
  // const adminNavNames = [
  //   { label: "Tarkastukseen tulevat", url: "inspections" },
  //   { label: "Virhekuvat", url: "cosmetic-remarks" },
  //   { label: "Myyntikuvat", url: "salesimages" },
  //   { label: "Mitat", url: "dimensions" },
  // ];
  const showNavbarPages = [
    '/admin/user', '/admin', '/admin/inspections'
  ]
  if (showNavbarPages.includes(window.location.href.replace(window.location.origin, ''))) {
    return (
      <>
        <NavDrawer
          drawerOpen={drawerOpen}
          setDrawerOpen={setDrawerOpen}
          navItems={navItems}
          navSubItems={navSubItems}
          isAdminPage={isAdminPage}
          adminNavNames={adminNavNames}
        />
        <div className="w-100" style={{ height: "72px" }}></div>
        <nav className="height-72 fixed-top bg-white py-3 z-index-to-1050 shadow-sm">
          <div className="container">
            <div className="d-flex flex-grow-1 align-items-center justify-content-between position-relative">
              <div className="d-flex align-items-center">
                <Link className="navbar-brand" to="/admin">
                  <img src={logo_black} alt="" height="20" className="me-2" />
                  Inspection tool
                </Link>
              </div>
              {isAdminPage ? (
                <AdminNavLinks
                  adminNavNames={adminNavNames}
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                />
              ) : (
                <BasicNavLinks
                  navItems={navItems0}
                  navSubItems={navSubItems}
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                />
              )}

              <div
                className="position-absolute w-100 rounde-10-bottom bg-white shadow-sm top-100 z-index-to-1000 hide-on-mobile-nav"
                style={{ height: isOpen ? "100px" : "0" }}
              ></div>
              <div
                className="box-size-30 fs-20 text-primary cursor-pointer d-lg-none d-xl-none d-xxl-none"
                onClick={() => setDrawerOpen(!drawerOpen)}
              >
                {drawerOpen ? <FaTimes /> : <FaBars />}
              </div>
            </div>
          </div>
        </nav>
      </>
    );
  } else {
    return null
  }

}
