import React , {useState,useEffect } from 'react';
import {useDropzone} from 'react-dropzone';
import {uploadFileApi} from '../../services/inspectionService';
import {ClipLoader} from "react-spinners";
import { FaTimes } from "react-icons/fa";

const DropZoneModal = props => {
  const {acceptedFiles, getRootProps, getInputProps} = useDropzone({
    accept: "image/*",
    multiple: true
  });
  const [fileUploaded, setfileUploaded] = useState(false);
  const [fileUploading, setfileUploading] = useState(false);
  const [tempFiles, setTempFiles] = useState([]);
  const [countChangingFileAction, setCountChangingFileAction] = useState(0);

  let files = tempFiles.map((file, idx) => (
    <div  key={idx} >
      {/* <li>
        {file.path} - {file.size} bytes
      </li> */}
      <div
        className="position-relative border p-2 m-1"
        style={{
          height: "200px",
          width: "200px",
        }}
      >
        <img
          src={URL.createObjectURL(file)}
          alt={file.name}
          className="cover-img"
        />
        <div
          onClick={() => remove(idx)}
          className="position-absolute top-0 end-0 box-size-20 bg-danger-15-transparent m-1 rounded-circle text-white fs-6 d-flex align-items-center justify-content-center cursor-pointer"
        >
          <FaTimes />
        </div>
      </div>
    </div>
  ));

  

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      console.log("New files are drop", acceptedFiles)
    }
    setTempFiles([...tempFiles, ...acceptedFiles]);
  }, [acceptedFiles]);

  const remove = idx => {
    tempFiles.splice(idx, 1);        // remove the file from the array
    setTempFiles(tempFiles)
    // this setCountChangingFileAction Will Make acceptedFiles Update in Dom
    setCountChangingFileAction(countChangingFileAction+1);
  };


  const handleUploadImages = () => {
    setfileUploading(true)
    for (let key in tempFiles) {
      let file = tempFiles[key]
      uploadFile(file);
    }
  }

  
  const removeAllFiles = () => {
    files = null;
    acceptedFiles.length = 0
    acceptedFiles.splice(0, acceptedFiles.length)
    // acceptedFiles = [];
    setTempFiles(acceptedFiles);
    files = null;
    setfileUploaded(false);
     // this setCountChangingFileAction Will Make acceptedFiles Update in Dom
    setCountChangingFileAction(countChangingFileAction+1);
    props.closeModal();
  }


  const uploadFile = (file) => {
    setfileUploaded(false);
		const formData = new FormData();

		formData.append('file', file);
		formData.append('results_id', props.item.inspection_result.data.id);
		formData.append('is_gallery', false);

    uploadFileApi(file, props.item.inspection_result.data.id)
      .then((result) => {
				console.log('uploadFile success status:', result.status);  
			})
			.catch((error) => {
				console.error('Error:', error);
			}).finally(()=>{
        setfileUploaded(true);
        setfileUploading(false);
        setTimeout(function() {
          removeAllFiles();
        }, 4000);
      });
	};
	

  return (
    <div
      className="modal fade"
      id={`dropZoneModal${props.item.inspection_result.data.id}`}
      tabIndex="-1"
      aria-labelledby={`dropZoneModalLabel${props.item.inspection_result.data.id}`}
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
        <div className="modal-content">
          <div className="modal-header">
          <h5
              className="fs-4 text-primary modal-title"
              id="dropZoneLabel"
            >
              Lisää kuvaa
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => removeAllFiles()}
            ></button>
          </div>
          <div className="modal-body">

            <section className="container">

              {/* <MyUploader /> */}

              <div {...getRootProps({className: 'dropzone'})}>
                <input {...getInputProps()} />
                <p>Vedä ja pudota tiedostoja tähän tai klikkaa tästä valitaksesi tiedostot. (png, jpg, jpeg)</p>
              </div>
              <aside>
                <h5>Tiedostot ({tempFiles.length})</h5>
                <h6>{fileUploaded && <p>Lataus onnistui</p>}</h6>
                <div className="w-100 d-flex flex-wrap">{files}</div>
              </aside>
            </section>
          </div>
          <div className="modal-footer">
          <button
              type="button"
              className="btn btn-primary"
              onClick={handleUploadImages}
              disabled={tempFiles.length < 1 || fileUploading || fileUploaded}
            >
              {(fileUploading || fileUploaded)
                        ? (fileUploaded ? 'Lataus onnistui' : <ClipLoader color={'#9B9B9B'} loading={(fileUploading)} css={''} size={15}/>)
                        : ' Lähetä kuvat '}
                        ({tempFiles.length})
                       
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={removeAllFiles}
              data-bs-dismiss="modal"
            >
              Sulje
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};



export default DropZoneModal;