import React, { Component, Fragment} from 'react';
import {Text, View} from '@react-pdf/renderer';
import pdfStyles from './pdfStyle';
import {getFormatTimestamp, parseISOString, isoFormatDMY} from '../../services/formatService'

class InspectionPdfGroup extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const groupHeader = (
      <View style={pdfStyles.groupHeader}>
        <Text>{this.props.groupName}</Text>
      </View>
    )
    // const items = [{a: 'aa', b: 'bb'}, {a: 'aab', b: 'bbb'}]
    let items = []
    try {
      const props_items = this.props.items
      items = props_items.map((i)=>{
        let value = i.attributes.inspection_result.data.attributes.value
        const kind = i.attributes.inspection_result.data.attributes.kind
        let value_reason = ''
        if (kind.startsWith("state")) {
          if (value===true) value = 'OK';
          if (value===false) {
            value = '-'
            value_reason = i.attributes.inspection_result.data.attributes.value_text
          }
        } else if (kind.startsWith("time")) {
          if (value) {
            const valueDateTime = parseISOString(value)
            if (kind.startsWith("timestamp")) {
              value = getFormatTimestamp(valueDateTime)[1]
            } else {
              value = isoFormatDMY(valueDateTime)
            }
          }
        } else if (kind.startsWith("check")) {
          if (value) {
            value = value.join(', ')
          }
        }
        return {
          name: i.attributes.name, 
          value: value, 
          value_reason: value_reason,
        }
      })
    } catch (error) {
      console.log("InspectionPdfGroup assign error", error);
      console.log("error item", this.props.items);
    }
    const rows = items.map((g, idx) => (
      <View style={pdfStyles.inspectionItemRow} key={idx}>
        <Text style={pdfStyles.inspectionItemName}>{g.name}</Text>
        <Text style={pdfStyles.inspectionItemValue}>{g.value} {g.value_reason}</Text>
      </View>
    ))
    
    return (<Fragment><View wrap={false} style={pdfStyles.groupTableView}>{groupHeader}{rows}</View></Fragment>);
  }
}



export default InspectionPdfGroup;