import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import CancelIcon from "@material-ui/icons/Cancel";
import LibraryAddCheckIcon from "@material-ui/icons/LibraryAddCheck";
import PhotoLibraryIcon from "@material-ui/icons/PhotoLibrary";
import BrokenImageIcon from "@material-ui/icons/BrokenImage";
import SettingsOverscanIcon from "@material-ui/icons/SettingsOverscan";
import {
  getGalleryIdFormInspection,
  getParentInfoByGallery,
  getTabStep
} from "../../services/inspectionService";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    zIndex: 1020,
    position: "fixed",
    top: 0,
  },
}));

const TabBar = (props) => {
  const classes = useStyles();
  const [tabNumber, setTabNumber] = useState(0);
  const [plate, setPlate] = useState("");
  const [inspectionId, setInspectionId] = useState("");
  const [galleryParentId, setGalleryParentId] = useState("");
  const [salesGalleryId, setSalesGalleryId] = useState("");
  const [callBackUrl, setCallBackUrl] = useState("/admin/inspections");

  useEffect(() => {
    const getGallery = (id) => {

      const localGallery = JSON.parse(localStorage.getItem(`gallery_${id}`));
      if (localGallery) {
        setGalleryParentId(localGallery.parentId);
        setSalesGalleryId(localGallery.salesId);
      } else {
        getGalleryIdFormInspection(id).then((result) => {
          console.log("TabBar getGalleryIdFormInspection", result);
          if (result) {
            setGalleryParentId(result.data.id);
            setSalesGalleryId(result.data.sales_id);
            localStorage.setItem(`gallery_${id}`, JSON.stringify(
              {
                parentId: result.data.id,
                salesId: result.data.sales_id
              }
            ))
          }
        });
      }

    };

    const getInspectionFromGallery = (id) => {
      getParentInfoByGallery(id).then((result) => {
        console.log("getInspectionFromGallery", result);
        if (result.data.status === "ok") {
          setInspectionId(result.data.data.inspection_id);
          setPlate(result.data.data.plate);
          setGalleryParentId(result.data.data.gallery_parent_id);
        }
      });
    };

    setPlate(props.plate);
    setTabNumber(props.tabNumber);
    setInspectionId(props.inspectionId);
    if (props.callBackUrl) setCallBackUrl(props.callBackUrl);
    if (props.inspectionId) {
      getGallery(props.inspectionId);
    }
    if (props.plate === "" && props.galleryId) {
      setSalesGalleryId(props.galleryId);
      // getInspectionFromGallery(props.galleryId);
    }
  }, [props]);


  const handleChange = (event, newValue) => {
    if (props.saveResult) {
      props.saveResult(false);
    }
    setTabNumber(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <div className="d-block d-sm-none align-self-center my-2 hide-on-mobile-nav">
          <Link to="/admin" className="text-decoration-none">
            {plate && `Ajoneuvo: ${plate}`}
          </Link>
        </div>
        <Tabs
          value={tabNumber}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="on"
          indicatorColor="primary"
          textColor="primary"
          aria-label="scrollable force tabs example"
        >
          <div className="d-none d-sm-block align-self-center me-2 ms-5">
            <Link to="/admin" className="text-decoration-none">
              {plate && `Ajoneuvo: ${plate}`}
            </Link>
          </div>
          <Tab
            label="Tarkastus"
            icon={<LibraryAddCheckIcon />}
            to={ getTabStep(inspectionId) ? `/admin/inspection_form` : `/admin/inspection_start`}
            // to={`/admin/inspection_form`}
            // to={`/admin/inspection/${inspectionId}`}
            component={Link}
            disabled={inspectionId === ""}
          />
          <Tab
            label="Virhekuvat"
            icon={<BrokenImageIcon />}
            to={`/admin/cosmetic-remarks/${galleryParentId}`}
            component={Link}
            disabled={galleryParentId === ""}
          />
          <Tab
            label="Myyntikuvat"
            icon={<PhotoLibraryIcon />}
            to={`/admin/salesimage/${salesGalleryId}`}
            component={Link}
            disabled={salesGalleryId === ""}
          />
          <Tab
            label="Mitat"
            icon={<SettingsOverscanIcon />}
            to={`/admin/dimension/${plate}?inspection=${inspectionId}`}
            component={Link}
            disabled={plate === ""}
          />
          <Tab
            label="Sulje"
            icon={<CancelIcon color="secondary" />}
            to={callBackUrl}
            component={Link}
          />
        </Tabs>
      </AppBar>
    </div>
  );
};

export default TabBar;
