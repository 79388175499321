import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class DimensionInputText extends Component {
  render() {
    const name  = this.props.name
    const value  = this.props.value
    const label  = this.props.label
    const inputClasses = "form-control border-start-0 border-end-0 border-top-0 rounded-0 no-shadow-btn mt-1";
    return (
      <div className="form-floating ps-0">
        <input
          readOnly={this.props.read_only}
          type="text"
          className={inputClasses}
          id={name}
          name={name}
          value={value}
          onChange={this.props.onChange}
        />
        <label htmlFor={name}>{label}</label>
      </div> 
    );
  }
}

DimensionInputText.propTypes = {
  onChange: PropTypes.func
};