import React, { Component } from 'react';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { withRouter } from 'react-router-dom';
import {FaEdit, FaMapMarkedAlt, FaTrash} from "react-icons/fa";
import { Button } from "reactstrap";
import {deleteDimensions} from "../../services/dimensionService";
import auth from "../../services/authService";

const currentUser = auth.getCurrentUser();
const email = currentUser ? currentUser.email : null;

class DimensionTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dimensions: this.props.dimensions,
      table_columns: [
        {
          formatter: this.editButton,
          dataField: 'id',
          text: 'Edit',
          classes: 'hoverable-bg',
          events: {
            onClick: (e, column, columnIndex, row, rowIndex) => {
                e.nativeEvent.preventDefault()
                e.stopPropagation()
                this.editClick(row)
            }
          },
          headerStyle: (column, colIndex) => {
            return { width: '80px' }; 
          },
          csvExport: false,
        },{
          dataField: 'formatted.plate',
          text: 'Rekisterinumero',
          classes: 'text-cell text_nowrap'
        },{
            dataField: 'formatted.description',
            text: 'Description',
            classes: 'text-cell text_nowrap'
        },{
          dataField: 'formatted.status',
          text: 'Status',
          classes: 'text-cell text_nowrap'
        },{
          dataField: 'formatted.a',
          text: 'A',
          classes: 'text-cell text_nowrap'
        },{
          dataField: 'formatted.b',
          text: 'B',
          classes: 'text-cell text_nowrap'
        },{
          dataField: 'formatted.c',
          text: 'C',
          classes: 'text-cell text_nowrap'
        },{
          dataField: 'formatted.d',
          text: 'D',
          classes: 'text-cell text_nowrap'
        },{
          dataField: 'formatted.e',
          text: 'E',
          classes: 'text-cell text_nowrap'
        },{
          dataField: 'formatted.f',
          text: 'F',
          classes: 'text-cell text_nowrap'
        },{
          dataField: 'formatted.g',
          text: 'G',
          classes: 'text-cell text_nowrap'
        },{
          dataField: 'formatted.h',
          text: 'H',
          classes: 'text-cell text_nowrap'               
        },{
          dataField: 'formatted.i',
          text: 'I',
          classes: 'text-cell text_nowrap'  
        },{
          dataField: 'formatted.j',
          text: 'J',
          classes: 'text-cell text_nowrap'                                                           
        },{
          formatter: this.deleteButton,
          dataField: 'formatted.user_email',
          text: 'Delete',
          classes: 'd-flex justify-content-center p-2',
          events: {
              onClick: (e, column, columnIndex, row, rowIndex) => {
                  e.nativeEvent.preventDefault()
                  e.stopPropagation()
                  this.deleteClick(row)
              }
          },
          csvExport: false,
        }],
      table_row_classes: null,
      pagination_options: {},  
    }
  }

  rowClick(e, row, index) {
    // console.log('rowClick!')
    // console.log(e)
    // console.log(row)
    // console.log(index)
    this.props.history.push(
      {
        pathname: '/admin/dimension_form/',
        state: { 
          id: row.id, 
          formatted: row.formatted 
        }
      }
    )
  }

  editClick = (row) => {
      this.props.history.push(
        {
          pathname: '/admin/dimension_form/',
          state: { 
            id: row.id, 
            formatted: row.formatted 
          }
        }
      )
  }

  deleteClick = (row) => {
    if (row.formatted.user_email===email) {
      const confirmBox = window.confirm(
        "Haluatko poistaa?"
      )
      if (confirmBox === "dfsdf") {
        deleteDimensions(row.id).then((result)=>{
          let new_dimensions = this.state.dimensions
          new_dimensions = new_dimensions.filter((d) => {
            return d.id !== row.id
          })
          this.setState({dimensions: new_dimensions})
        })
      }
    }
  }

  editButton() {
    return <Button className={'map-button w-100 h-100'}><FaEdit/></Button>
  }

  deleteButton(cell) {
    console.log("deleteButton",cell)
    return <Button className={'map-button w-100 h-100'} disabled={cell!==email}><FaTrash/></Button>
  }

  render() {
    return ( this.state.dimensions?.length > 0 && <BootstrapTable
      keyField='id'
      data={this.state.dimensions}
      columns={this.state.table_columns}
      rowClasses={ this.state.table_row_classes }
      pagination={ paginationFactory(this.state.pagination_options) }
      rowEvents={ {onClick: this.rowClick.bind(this)} }
      hover
    /> )
  }
}

export default withRouter(DimensionTable);