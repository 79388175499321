import React, { Component } from "react";
import { Card } from "react-bootstrap";
import imageEmpty from "../../images/empty.png";
import dimensionSedan from "../../images/dimension_sedan.jpg";
import dimensionVan from "../../images/dimension_van.jpg";
import dimensionWagon from "../../images/dimension_wagon.jpg";
import DimensionInputText from "./DimensionInputText";
import DimensionInputNumber from "./DimensionInputNumber";
import { FaSave, FaArrowLeft, FaSearch } from "react-icons/fa";
import {
  saveDimensions,
  getDimensionNames,
  createDimensions,
  getSimilarModels,
} from "../../services/dimensionService";
import { getCarInfo } from "../../services/inspectionService";
import Select from "react-select";
import { InputGroup, FormControl, Button } from "react-bootstrap";
import { ClipLoader } from "react-spinners";
import DimensionSimilarModel from "./DimensionSimilarModel";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TabBar from "../navbar/TabBar";
import { getGalleryIdFormInspection } from "../../services/inspectionService";
import DimensionsModal from "./DimensionsModal";

class DimensionForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      plate: null,
      body_type: null,
      description: null,
      a: null,
      b: null,
      c: null,
      d: null,
      e: null,
      f: null,
      g: null,
      h: null,
      i: null,
      j: null,
      measutingAllNames: [],
      measutingNames: {
        a: null,
        b: null,
        c: null,
        d: null,
        e: null,
        f: null,
        g: null,
        h: null,
        i: null,
        j: null,
      },
      bodyTypesAll: [],
      bodyTypes: [],
      loadingCarInfo: false,
      netwheelsBodyTypes: null,
      make: null,
      model: null,
      model_year: null,
      body_type_id: null,
      similarModels: [],
      isComplete: false,
      inspection: "",
      brokenImageId: "",
    };
    this.modalBtn = React.createRef();
  }

  init() {
    if (this.props.location.state && this.props.location.state.id) {
      this.setState({
        id: this.props.location.state.id,
        plate: this.props.location.state.formatted.plate,
        body_type: this.props.location.state.formatted.body_type,
        description: this.props.location.state.formatted.description,
        a: this.props.location.state.formatted.a,
        b: this.props.location.state.formatted.b,
        c: this.props.location.state.formatted.c,
        d: this.props.location.state.formatted.d,
        e: this.props.location.state.formatted.e,
        f: this.props.location.state.formatted.f,
        g: this.props.location.state.formatted.g,
        h: this.props.location.state.formatted.h,
        i: this.props.location.state.formatted.i,
        j: this.props.location.state.formatted.j,
        isComplete: this.props.location.state.formatted.status === "finish",
      });
    }

    if (this.props.location.state.inspection) {
      this.setState({ inspection: this.props.location.state.inspection });
      this.getGallery(this.props.location.state.inspection);
    }

    if (this.props.location.state.new_plate) {
      this.setState({
        plate: this.props.location.state.new_plate,
      });
    }
  }

  componentDidMount() {
    this.init();
    getDimensionNames("BODY_TYPES").then((response) => {
      this.setState({ bodyTypesAll: response.data });
      let types = response.data.map((a) => {
        return { value: a.type, label: a.fi };
      });
      this.setState({ bodyTypes: types });
    });

    getDimensionNames("MEASURING_NAMES").then((response) => {
      this.setState({ measutingAllNames: response.data });
      if (this.props.location.state.formatted) {
        const body_type = this.props.location.state.formatted.body_type;
        this.setMeasuringNames(body_type, response.data);
      }
    });

    getDimensionNames("NETWHEELS_BODY_TYPE").then((response) => {
      this.setState({ netwheelsBodyTypes: response.data });
    });
  }

  setMeasuringNames = (body_type, allNames) => {
    const response_names = allNames.filter(
      (option) => option.type === body_type
    );
    if (response_names[0]) {
      let names = response_names[0].names.reduce(function (map, obj) {
        map[obj.code] = obj.fi;
        return map;
      }, {});
      this.setState({ measutingNames: names });
    }
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleCheckComplete = (event) => {
    this.setState({ isComplete: event.target.checked });
  };

  handleChangeBody = (e) => {
    this.setState({ body_type: e.value });
    this.setMeasuringNames(e.value, this.state.measutingAllNames);
    this.checkSimilar();
  };

  handleClickSimilarModel = (e) => {
    const values = e.target.value.split("|");
    this.setMeasuringValues(values);
  };

  setMeasuringValues = (values) => {
    if (values.length === 10) {
      this.setState({
        a: values[0],
        b: values[1],
        c: values[2],
        d: values[3],
        e: values[4],
        f: values[5],
        g: values[6],
        h: values[7],
        i: values[8],
        j: values[9],
      });
    }
  };

  checkSimilar = async () => {
    getSimilarModels(
      this.state.make,
      this.state.model,
      this.state.model_year,
      this.state.body_type_id,
      this.state.plate
    ).then((result) => {
      if (
        result.data.similar_models &&
        result.data.similar_models.result === "found"
      ) {
        const models = result.data.similar_models.data;
        this.setState({ similarModels: models });
      }
    });
  };

  checkPlate = async (plate) => {
    this.setState({ loadingCarInfo: true });
    getCarInfo(plate)
      .then((result) => {
        if (result.data && result.data.data.attributes.make) {
          const info = result.data.data.attributes;
          // body_type: null,
          this.setState({
            body_type_id: info.body_type,
            make: info.make,
            model: info.model,
            model_year: info.model_year,
          });
          let desc = `${info.make} ${info.model}  ${info.model_year}`;
          if (info.body_type && this.state.netwheelsBodyTypes) {
            desc += " " + this.state.netwheelsBodyTypes[info.body_type];
            let filtered = this.state.bodyTypesAll.filter((x) =>
              x.netwheels.includes(String(info.body_type))
            );
            if (filtered[0]) {
              this.setState({ body_type: filtered[0].type });
              this.setMeasuringNames(
                this.state.body_type,
                this.state.measutingAllNames
              );
            }
          }
          this.setState({ description: desc });
        } else {
          this.setState({ description: "Not found" });
        }
      })
      .then(() => {
        this.checkSimilar();
        this.setState({ loadingCarInfo: false });
      });
  };

  getGallery = (id) => {
    getGalleryIdFormInspection(id).then((result) => {
      if (result) {
        this.setState({ brokenImageId: result.data.id });
      }
    });
  };

  handleSave = () => {
    let dimensionParams = {
      plate: this.state.plate,
      body_type: this.state.body_type,
      description: this.state.description,
      a: this.state.a,
      b: this.state.b,
      c: this.state.c,
      d: this.state.d,
      e: this.state.e,
      f: this.state.f,
      g: this.state.g,
      h: this.state.h,
      i: this.state.i,
      j: this.state.j,
    };
    if (this.state.isComplete) {
      dimensionParams = Object.assign(dimensionParams, { status: "finish" });
    }

    if (this.state.id) {
      saveDimensions(this.state.id, dimensionParams).then((response) => {
        this.modalBtn.current.click();
      });
    } else {
      createDimensions(dimensionParams).then((response) => {
        this.modalBtn.current.click();
      });
    }
  };

  containerCard = (
    id,
    body_type,
    plate,
    description,
    a,
    b,
    c,
    d,
    e,
    f,
    g,
    h,
    i,
    j,
    measutingNames,
    bodyTypes
  ) => {
    let imgBodyType = (body_type) => {
      // wagon, van, sedan
      switch (body_type) {
        case "sedan":
          return <img className="img-fluid" src={dimensionSedan} alt="Sedan" />;
        case "van":
          return <img className="img-fluid" src={dimensionVan} alt="Van" />;
        case "wagon":
          return <img className="img-fluid" src={dimensionWagon} alt="Wagon" />;
        default:
          return <img className="img-fluid" src={imageEmpty} alt="Sedan" />;
      }
    };

    return (
      <Card className="mt-3">
        <Card.Header>
          {this.props.location.state.new_plate ? "Uusi Mitta" : "Mittatiedot"}
        </Card.Header>
        <Card.Body>
          <div className="row">
            <div className="col-12">
              <div className="form-floating ps-0">
                <InputGroup className="p-2">
                  <FormControl
                    placeholder="Rekisterinumero"
                    aria-label="Rekisterinumero"
                    id="plate"
                    name="plate"
                    value={plate}
                    readOnly={id ? true : false}
                    onChange={this.handleChange}
                  />
                  <InputGroup.Append>
                    <Button
                      variant="outline-secondary"
                      disabled={id ? true : false}
                      onClick={() => {
                        this.checkPlate(plate);
                      }}
                    >
                      {this.state.loadingCarInfo ? (
                        <ClipLoader
                          color={"#9B9B9B"}
                          loading={this.state.loading}
                          css={""}
                          size={15}
                        />
                      ) : (
                        <FaSearch />
                      )}
                    </Button>
                  </InputGroup.Append>
                </InputGroup>
              </div>
              <DimensionInputText
                name="description"
                value={description}
                label="Auton tiedot"
                onChange={this.handleChange}
              />
            </div>
            <div className="col-12 ">
              <div className="p-2">
                {/* <label className="form-label">Korimalli</label> */}
                <Select
                  name="status"
                  options={bodyTypes}
                  onChange={this.handleChangeBody}
                  placeholder="Valitse Korimalli..."
                  // isSearchable
                  value={bodyTypes.filter(
                    (option) => option.value === body_type
                  )}
                />
              </div>
            </div>
            <div className="col-12 p-4">
              <div>
                <Button
                  variant="outline-secondary"
                  onClick={() => {
                    this.checkSimilar();
                  }}
                >
                  <FaSearch />
                </Button>
                <span className="ms-1">
                  Napsauta valitaksesi mitat nykyisistä malleista
                </span>
              </div>
              {this.state.similarModels.map((x, idx) => (
                <DimensionSimilarModel
                  key={idx}
                  value={`${x.a}|${x.b}|${x.b}|${x.d}|${x.e}|${x.f}|${x.g}|${x.h}|${x.i}|${x.j}`}
                  onClick={this.handleClickSimilarModel}
                  body_type={x.body_type}
                  display_text={`${x.description} A.${x.a} B.${x.b} C.${x.c} D.${x.d} E.${x.e} F.${x.f} G.${x.g} I.${x.i} J.${x.j}`}
                />
              ))}
            </div>
            <div className="col-md-6 col-lg-6 col-sm-12">
              {imgBodyType(body_type)}
            </div>
            <div className="col-md-6 col-lg-6 col-sm-12">
              {measutingNames.a && (
                <DimensionInputNumber
                  name="a"
                  value={a}
                  label={measutingNames.a}
                  onChange={this.handleChange}
                />
              )}
              {measutingNames.b && (
                <DimensionInputNumber
                  name="b"
                  value={b}
                  label={measutingNames.b}
                  onChange={this.handleChange}
                />
              )}
              {measutingNames.c && (
                <DimensionInputNumber
                  name="c"
                  value={c}
                  label={measutingNames.c}
                  onChange={this.handleChange}
                />
              )}
              {measutingNames.d && (
                <DimensionInputNumber
                  name="d"
                  value={d}
                  label={measutingNames.d}
                  onChange={this.handleChange}
                />
              )}
              {measutingNames.e && (
                <DimensionInputNumber
                  name="e"
                  value={e}
                  label={measutingNames.e}
                  onChange={this.handleChange}
                />
              )}
              {measutingNames.f && (
                <DimensionInputNumber
                  name="f"
                  value={f}
                  label={measutingNames.f}
                  onChange={this.handleChange}
                />
              )}
              {measutingNames.g && (
                <DimensionInputNumber
                  name="g"
                  value={g}
                  label={measutingNames.g}
                  onChange={this.handleChange}
                />
              )}
              {measutingNames.h && (
                <DimensionInputNumber
                  name="h"
                  value={h}
                  label={measutingNames.h}
                  onChange={this.handleChange}
                />
              )}
              {measutingNames.i && (
                <DimensionInputNumber
                  name="i"
                  value={i}
                  label={measutingNames.i}
                  onChange={this.handleChange}
                />
              )}
              {measutingNames.j && (
                <DimensionInputNumber
                  name="j"
                  value={j}
                  label={measutingNames.j}
                  onChange={this.handleChange}
                />
              )}
            </div>
          </div>
          <div className="col-12 text-center">
            <FormControlLabel
              control={
                <Checkbox
                  onChange={this.handleCheckComplete}
                  checked={this.state.isComplete}
                  name="checkedA"
                  color="info"
                />
              }
              label="Mittaus suoritettu loppuun"
            />
          </div>
        </Card.Body>
        <Card.Footer className="text-center">
          <button
            className="btn btn-primary text-white fs-14 rounded-pill me-2 text-nowrap"
            onClick={() => this.props.history.goBack()}
          >
            <span className="fs-12 me-1">
              <FaArrowLeft />
            </span>
            <span className="ms-1">Takaisin</span>
          </button>
          <button
            className="btn btn-primary text-white fs-14 rounded-pill me-2 text-nowrap"
            onClick={this.handleSave}
          >
            <span className="fs-12 me-1">
              <FaSave />
            </span>
            <span className="me-1">Tallenna</span>
          </button>
        </Card.Footer>
      </Card>
    );
  };

  render() {
    const {
      id,
      body_type,
      plate,
      description,
      a,
      b,
      c,
      d,
      e,
      f,
      g,
      h,
      i,
      j,
      measutingNames,
      bodyTypes,
    } = this.state;
    return (
      <main>
        {id ? (
          <TabBar
            callBackUrl="/admin/inspections"
            tabNumber={4}
            plate={plate}
            inspectionId={this.state.inspection}
            brokenImageId={this.state.brokenImageId}
            dimensionId={plate}
          />
        ) : (
          <TabBar
            callBackUrl="/admin/inspections"
            tabNumber={4}
            plate={plate}
            inspectionId={this.state.inspection}
            brokenImageId={this.state.brokenImageId}
            dimensionId={plate}
          />
        )}
        <div className="container mt-100">
          {this.containerCard(
            id,
            body_type,
            plate,
            description,
            a,
            b,
            c,
            d,
            e,
            f,
            g,
            h,
            i,
            j,
            measutingNames,
            bodyTypes
          )}
        </div>
        <button
          ref={this.modalBtn}
          className="d-none btn btn-primary btn-sm"
          data-bs-toggle="modal"
          data-bs-target="#dimensionsModal"
        >
          Tallenna
        </button>
        <DimensionsModal />
      </main>
    );
  }
}

export default DimensionForm;
