import React, { Component } from 'react';
import { FaPlus} from "react-icons/fa";
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
import dimensionService from "../../services/dimensionService";
import Select from 'react-select';

export default class  DimensionSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      plate: null,
      status: 'wait-own'
    }
  }

  handelPlateChange = (e) => {
    this.setState({plate: e.target.value },() => {
        this.pushChanges()
    });
  }

  handleChangeStatus = (e) => {
    console.log("handleChangeStatus", e.value)
    this.setState({status: e.value},() => {
        this.pushChanges()
    });
  }

  pushChanges() {
    this.fetchDimensions(this.state.plate, this.state.status)
  } 

  fetchDimensions(plate, status){
    this.props.beginFetch();
    dimensionService.getDimensions(plate, status).then((response) => {
      this.props.updateDimensions(
          response,
          {aa: 1}
          );
    });  
  }

  statusOptions = [
    { value: 'wait-own', label:  'Odottaa mittausta'},
    { value: 'finish', label:  'Mittaus Valmis'},
  ]

  render() {
    return (
          <div className="row">
            <div className="col-4">
              <div className="mb-3">
                <label htmlFor="plate" className="form-label">
                  Rekisterinumero
                </label>
                <input
                  type="text"
                  className="form-control no-shadow-btn"
                  name="plate"
                  onChange={this.handelPlateChange}
                  value={this.state.plate}
                />
              </div>
            </div>
            <div className="col-4">
              <label className="form-label">Tilanne</label>
              <Select
                name="status"
                options={this.statusOptions}
                onChange={this.handleChangeStatus}
                placeholder='Tlanne...'
                isSearchable
                value={this.statusOptions.filter(option => option.value === this.state.status)}
              />
            </div>
            <div className="col-3 text-end">
              <Link
                to={{
                  pathname: "/admin/dimension_form",
                  state: {
                    display: 'new'
                  }
                }}
                className="btn btn-primary text-white fs-14 rounded-pill me-2 text-nowrap" >
                <span className="fs-12 me-1">
                  <FaPlus />
                </span>
                <span className="me-1">Uusi Mitta</span>
              </Link>
            </div>
          </div>
    );
  }
}

DimensionSelector.propTypes = {
  onChange: PropTypes.func
};