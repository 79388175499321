import axios from "axios";
import auth from "./authService";

function setToken(token) {
  axios.defaults.headers = {
    Authorization: "Token " + token,
  };
}


export async function postRequest(url, params) {
  const token = auth.getToken();
  return await axios.post(url, params, {
    headers: {
      Authorization: "Token " + token,
    },
  })
      .catch(error => console.log(error));
}
export async function updateRequest(url, params) {
  const token = auth.getToken();
  return await axios.patch(url, params, {
    headers: {
      Authorization: "Token " + token,
    },
  })
      .catch(error => console.log(error));
}

export async function deleteRequest(url) {
  const token = auth.getToken();
  return await axios.delete(url, {
    headers: {
      Authorization: "Token " + token,
    },
  })
      .catch(error => console.log(error));
}

export async function getRequest(url, params={}){
  const token = auth.getToken();
  return await axios.get(url, {
    params: params,
    headers: {
      Authorization: "Bearer " + token,
    },
  })
      .catch(error => console.log(error));
}


export async function postFormDataRequest(url, formData) {
  const token = auth.getToken();
  return await axios.post(url, formData, {
    headers: {
      Authorization: "Token " + token,
      'content-type': 'multipart/form-data'
    },
  })
      .catch(error => console.log(error));
}

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  setToken,
};
