import React , {useState}  from 'react';
import Select from 'react-select';
import {
  checkPlateUrl, 
  apiRequest, 
  createUrl
  // saveTabStep
} 
from '../../services/inspectionService';
import auth from "../../services/authService";
import { withRouter } from 'react-router-dom';
import {InputGroup , FormControl, Button} from 'react-bootstrap'
import {FaSave, FaSearch, FaClipboardCheck, FaImages, FaRuler} from  "react-icons/fa";
import { SyncLoader } from "react-spinners";
import queryString from "query-string";

const InspectionStartForm = props => {

  const currentUser = auth.getCurrentUser();
  const email = currentUser ? currentUser.email : null;
  const currentUserEmail = currentUser ? currentUser.email : '';
  const currentUserPhone = currentUser ? currentUser.phone : '';
  const currentUserName = currentUser ? currentUser.fullname : '';
  // const user = currentUser ? currentUser.user : null;
  // const email = user ? user.email : null;
  const inputClasses = "form-control border-start-0 border-end-0 border-top-0 rounded-0 no-shadow-btn";

  const [isSaving, setIsSaving] = useState(false)
  const [isSavingStartForm, setisSavingStartForm] = useState(false)
  const [isSelectPhase, setIsSelectPhase] = useState(false)
  const [showLocation, setShowLocation] = useState(false)
  const [formParams, setFormParams] = useState(() => {
    if (props.id) {
      if (props.place_name && props.place_name.startsWith("Autolle.com")) {
        setShowLocation(true)
      }
      return {
        plate: props.plate,
        make: props.make,
        model: props.model,
        vm: props.vm,
        mileage: props.mileage,
        info: (props.info) ? props.info : email,
        place_name: props.place_name,
        phase_name: props.phase_name,
        need_notification: props.need_notification,
        created_by: props.created_by,
        location: props.inspection_location
      }
    } else {
      if (props.placeOptions.length > 0) {
        const placeOption = props.placeOptions.filter((option) => option.value === props.place)
        if (placeOption.length > 0) {
          if (placeOption[0].label.startsWith("Autolle.com")) {
            setShowLocation(true)
          }
        }
      }

      return {
        place: props.place,
        phase: null,
        plate: props.plate,
        make: null,
        model: null,
        vm: null,
        mileage: null,
        info: email,
        place_name: null,
        phase_name: null,
        placeOptions: props.placeOptions,
        created_by: {
          email: currentUserEmail,
          name:currentUserName,
          phone: currentUserPhone,
        },
        need_notification: null
      }
    }
  });

  let phaseOptions = null
  let phaseFilteredOptions = null
  if (props.places) {
      phaseOptions = props.places.map((p) => {
      return {
        label: p.attributes.name,  
        link: p.id,
        options: p.attributes.check_phases.data.map((s) => {
          return { label: `${s.attributes.step} ${s.attributes.name}` , value: s.id, link: p.id}
        })
      }
    })
    phaseFilteredOptions = phaseOptions.filter((o) => o.link === formParams.place)
  } 
  
  const locationOptions = [
    { value: 'oulu', label: 'Oulu' },
    { value: 'vantaa', label: 'Vantaa' },
    { value: 'kuopio', label: 'Kuopio' },
    { value: 'tuusula', label: 'Tuusula' },
    { value: 'raisio', label: 'Raisio' }
  ]

  const checkPlate = async (plate) => { 
    apiRequest(checkPlateUrl(plate)).then((result) => {  
      if (result && result.type === "vehicle_datum") {
        const info = result.attributes
        setFormParams({ ...formParams, make: info.make });
        setFormParams({ ...formParams, model: info.model });
        setFormParams({ ...formParams, vm: info.model_year });
      } else {
        setFormParams({ ...formParams, make: "-" });
        setFormParams({ ...formParams, model: "-" });
        setFormParams({ ...formParams, vm: "-" });
      }
    });
  }

  const handleChange = (e) => {
    setFormParams({ ...formParams, [e.target.name]: e.target.value });
  };

  const handleChangeNotification = (e) => {
    setFormParams({ ...formParams, [e.target.name]: e.target.checked });
  };
  
  const handleChangePlate = (plate) => {
    // checkPlate(plate);
    setFormParams({ ...formParams, ['plate']: plate });
  }

  const handleChangeSelect = (selector, event) => {
    if (event) { 
      if (selector==="place") { 
        setFormParams({ ...formParams, ['phase']: null }) 
        if (event.label.startsWith("Autolle.com")) {
          setShowLocation(true)
        } else {
          setShowLocation(false)
        }
      } else {
        setIsSelectPhase(true)
      }
      setFormParams({ ...formParams, [selector]: event.value }) 
    };
  };


  const handleSubmit = (event) => {
    event.preventDefault();
    setIsSaving(true); 
    const {place, phase, plate,  mileage, make, model, vm, info, need_notification, location} = event.target.elements

    let body = {
      place_id: place.value,
      phase_id: phase.value,
      plate: plate.value,
      mileage: mileage.value,
      make: make.value,
      model: model.value,
      model_year: vm.value,
      info: info.value,
      need_notification: need_notification.checked,
      location: location?.value,
      created_by: {name: currentUserName, email: currentUserEmail, phone: currentUserPhone}
    }
    console.log("body NEW!!!", body)
    apiRequest(createUrl(),"POST", body).then((result) => {  
      if (result) {
        localStorage.setItem('currentInspection', JSON.stringify(result));
        return true
      } else {
        alert("Server timeout: inspection is creating in background please refresh after few minute.");
        return false
      }
    }).then((result) => {
      console.log("apiRequest result 2", result)
      if (result) {
        props.history.push('/admin/inspection_form')
      }
    });
    
    //  Case after create start Inspect immdietly
    // apiRequest(createUrl(),"POST", body).then((result) => {  
    //   console.log(result);
    //   localStorage.setItem('currentInspection', JSON.stringify(result));
    // }).then(() => {
    //   props.history.push('/admin/inspection_form')
    // });


  }

  const handleEdit = (id) => {
    saveStartForm(id, function() {
      setisSavingStartForm(false);
    });
  }

  function saveStartForm(id, callback) {
    setisSavingStartForm(true);
    if (true) {
      let body = {
        inspection: {
          mileage: formParams.mileage,
          info: formParams.info,
          plate: formParams.plate,
          make: formParams.make,
          model: formParams.model,
          model_year: formParams.vm,
          need_notification: formParams.need_notification,
          location: formParams.location
        }
      }
      apiRequest(createUrl() + "/" + id, "PUT", body).then((result) => {  
        callback();
      });
    } else {
      callback();
    }
  } 

  const handleEditInspection = (id) => {
    saveStartForm(id, function() {
      setisSavingStartForm(false);
      setIsSaving(true);
      let body = {
        inspection: {
          mileage: formParams.mileage,
          info: formParams.info
        }
      }
      apiRequest(createUrl() + "/" + id, "PUT", body).then((result) => {  
        // saveTabStep( JSON.stringify(result).id , 0)
        localStorage.setItem('currentInspection', JSON.stringify(result));
      }).then(() => {
        props.history.push('/admin/inspection_form')
      });
    });
  }

  return (
      <div className="w-100">
          <form  onSubmit={handleSubmit} action="#">
          <div className="form-floating ps-0 mb-2">
            { props.id
              ? <><input
                  readOnly
                  className={inputClasses}
                  id="place"
                  name="place"
                  placeholder="Toimipiste"
                  value={formParams.place_name}
                />
                <label htmlFor="place">Toimipiste</label></>
              : <Select
                name="place"
                id="place"
                options={formParams.placeOptions}
                onChange={event => {
                    handleChangeSelect("place", event);
                  }}
                placeholder='Toimipiste...'
                isSearchable
                // isClearable
                value={formParams.placeOptions.filter(option => option.value === formParams.place)}
              />}
          </div>
          <div className="form-floating ps-0 mb-2">
            { props.id
              ? <><input
                  readOnly
                  className={inputClasses}
                  id="phase"
                  name="phase"
                  value={formParams.phase_name}
                /><label htmlFor="phase">Tarkistus vaihe</label></>
              : <Select
              name="phase"
              options={phaseFilteredOptions}
              onChange={event => handleChangeSelect("phase", event)}
              placeholder='Tarkistus vaihe...'
              isSearchable
              value={phaseFilteredOptions[0] && phaseFilteredOptions[0].options.filter(option => option.value === formParams.phase)}
            />}
          </div>
          {showLocation &&
            (<div className="form-floating ps-0 mb-2">
              <div className='text-secondary ms-3'>Sijainti</div>
              <Select
                name="location"
                options={locationOptions}
                placeholder="Valitse sijainti..."
                isSearchable
                onChange={event => handleChangeSelect("location", event)}
                value={locationOptions.filter(option => option.value === formParams.location)}
              />
            </div>)}
          <div className="form-floating ps-0">
            <div className='text-secondary ms-3'>Rekisterinumero</div>
            <InputGroup className="mb-3">
              <FormControl
                type="text"
                placeholder="Rekisterinumero"
                aria-label="Rekisterinumero"
                aria-describedby="basic-addon2"
                id="plate"
                name="plate"
                value={formParams.plate}
                // readOnly={props.id ? true : false}
                onChange={handleChange}
              />
              <InputGroup.Append>
                <Button variant="outline-secondary" onClick={() => { handleChangePlate(formParams.plate) }} >
                  <FaSearch/>
                </Button>
              </InputGroup.Append>
            </InputGroup>
          </div>
          <div className="form-floating ps-0">
            <input
                // readOnly
                type="text"
                className="form-control border-start-0 border-end-0 border-top-0 rounded-0 no-shadow-btn"
                id="make"
                name="make"
                onChange={handleChange}
                value={formParams.make}
              />
            <label htmlFor="make">Merkki</label>
          </div>
          <div className="form-floating ps-0">
            <input
                type="text"
                className={inputClasses}
                id="model"
                name="model"
                placeholder="Malli"
                onChange={handleChange}
                value={formParams.model}
              />
            <label htmlFor="model">Malli</label>
          </div>
          <div className="form-floating ps-0">
            <input
              type="text"
              className={inputClasses}
              id="vm"
              name="vm"
              onChange={handleChange}
              value={formParams.vm}
            />
            <label htmlFor="vm">Vuosi Malli</label>
          </div>
          <div className="form-floating ps-0">
            <input
                type="text"
                className={inputClasses}
                id="mileage"
                name="mileage"
                onChange={handleChange}
                value={formParams.mileage}
              />
            <label htmlFor="mileage">Mittarilukema</label>
          </div>
          <div className="form-floating ps-0">
            <input
              type="text"
              className={inputClasses}
              id="info"
              name="info"
              value={formParams.info}
              onChange={handleChange}
            />
            <label htmlFor="note">Tarkastaja / Merkintä</label>
          </div>
          <div className=" ps-3">
            <input 
              type="checkbox" 
              id="need_notification" 
              name="need_notification"
              className='me-1'
              onChange={handleChangeNotification}
              checked={formParams.need_notification}
            />
            <label htmlFor="need_notification">Tarvitse ilmoitusta (SMS {formParams.created_by && formParams.created_by.phone})</label>
          </div>
          <div className="w-100 my-3 d-flex justify-content-center">
          {props.id
              ? <div className="row w-100">
                 <div className="col-md-6 col-xs-12  mb-2">
                    <button 
                      type="button"  
                      onClick={() => { handleEdit(props.id) }}
                      className="btn btn-primary text-white rounded-pill d-flex align-items-center me-2 mt-1 w-100 justify-content-center">
                        {isSavingStartForm 
                          ?<SyncLoader color='#ffffff'/>
                          :(<><FaSave className="me-1" /> Tallenna</>)}
                    </button>
                  </div>
                  <div className="col-md-6 col-xs-5  mb-2">
                    <button
                    type="button"
                    onClick={() => { handleEditInspection(props.id) }}
                    className="btn btn-primary text-white rounded-pill d-flex align-items-center me-2 mt-1 w-100 justify-content-center"
                    >
                      { isSaving 
                      ?<SyncLoader color='#ffffff'/>
                      :(<><FaClipboardCheck className="me-1" /> Jatka tarkastus</>)}
                    </button>
                  </div>
                </div>
              : <div>
                { isSaving
                      ?(<button disabled={true}
                        className="btn btn-primary text-white rounded-pill d-flex align-items-center me-2 mt-1 w-100 justify-content-center">
                        <SyncLoader color='#ffffff'/>
                        </button>)
                      :(<input 
                          type="submit" value="Aloita tarkastus"  disabled={!isSelectPhase}
                          className="btn btn-primary text-white rounded-pill d-flex align-items-center" />)}
                </div>
              } 
          </div>
        </form>   

      </div>
  );
};


export default withRouter(InspectionStartForm);