import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Link } from "react-router-dom";
import { FaEdit } from "react-icons/fa";
import { Button } from "reactstrap";

const editButton = () => {
  return (
    <Button className={"map-button w-100 h-100"}>
      <FaEdit />
    </Button>
  );
};

// const editButton = () => {
//   return (
//     <Link to="/" className="map-button w-100 h-100">
//       <span className=" d-flex align-items-center justify-content-center">
//         <FaEdit />
//       </span>
//     </Link>
//   );
// };

function MainGalleryPage({ galleries, history, template }) {
  const handleRowClick = (row) => {
    let pathname =
      template === "sales"
        ? `/admin/salesimage/${row.id}`
        : `/admin/cosmetic-remarks/${row.id}`;
    history.push({
      pathname,
    });
  };

  const tableColumns = [
    {
      formatter: editButton,
      dataField: "id",
      text: "",
      classes: "hoverable-bg",
      attrs: { title: "id column" },
      csvExport: false,
      headerStyle: (column, colIndex) => {
        return { width: "80px" };
      },
    },
    {
      dataField: "plate",
      text: "Rekisteritunnus",
      classes: "text-cell text_nowrap",
    },
    {
      dataField: "description",
      text: "Lisätiedot",
      classes: "text-cell text_nowrap",
    },
    {
      dataField: "status",
      text: "Status",
      classes: "text-cell text_nowrap",
    },
  ];
  return (
    <div className="w-100">
      <div className="w-100 border rounded shadow-sm">
        <div className="responsive-table">
          <BootstrapTable
            keyField="id"
            id="id"
            data={galleries}
            columns={tableColumns}
            pagination={paginationFactory()}
            rowEvents={{
              onClick: (e, row) => handleRowClick(row),
            }}
            hover
          />
        </div>
      </div>
    </div>
  );
}

export default MainGalleryPage;
