import React from "react";
import {getCurrentUser} from "../services/authService";

const UserProfile = () => {
  return (
    <div className='container'>
        <h1>Käyttäjätiedot</h1>
        <div className="table-responsive">
            <table className="table table-striped table-borderless table-hover">
                <thead>
                </thead>
                <tbody>
                <tr>
                    <td>Nimi</td>
                    <td>{getCurrentUser().fullname}</td>
                </tr>
                <tr>
                    <td>Email</td>
                    <td>{getCurrentUser().email}</td>
                </tr>
                <tr>
                    <td>Puhelin</td>
                    <td>{getCurrentUser().phone}</td>
                </tr>
                <tr>
                    <td>Roolit</td>
                    <td>{getCurrentUser().business_roles?.join(', ')}</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
  );
};

export default UserProfile;
