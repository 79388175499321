import React from "react";

const range = (start, size) => {
  let r = [];
  for (let i = start; i < size; i++) {
    r.push(i);
  }
  return r;
};

function Pagination(props) {
  const { itemsCount, pageSize, currentPage, onPageChange } = props;
  const pagesCount = Math.ceil(itemsCount / pageSize);
  if (pagesCount === 1) return null;
  const pages = range(1, pagesCount + 1);
  return (
    <nav aria-label="Page navigation example">
      <ul className="pagination">
        {currentPage - 1 > 0 && (
          <li className="page-item">
            <a
              onClick={() => onPageChange(currentPage - 1)}
              className="page-link"
              href="#"
              aria-label="Previous"
            >
              <span aria-hidden="true">&laquo;</span>
            </a>
          </li>
        )}
        {pages.map((page) => (
          <li
            key={page}
            className={page === currentPage ? "page-item active" : "page-item"}
          >
            <a onClick={() => onPageChange(page)} className="page-link">
              {page}
            </a>
          </li>
        ))}
        {currentPage + 1 <= pagesCount && (
          <li className="page-item">
            <a
              onClick={() => onPageChange(currentPage + 1)}
              className="page-link"
              href="#"
              aria-label="Next"
            >
              <span aria-hidden="true">&raquo;</span>
            </a>
          </li>
        )}
      </ul>
    </nav>
  );
}

export default Pagination;
