import React, { useState, useEffect, useRef } from "react";

import InsideImage from "../../images/cosmetic_bg/inside.png";
import Outsidemage from "../../images/cosmetic_bg/outside.png";
import { ClipLoader } from "react-spinners";

const dotIntialValues = {
  parent_id: "",
  x: 0,
  y: 0,
  attach_image: "",
  is_gallery: true,
  is_request_url: true,
};

const carFrameImage = {
  inside: InsideImage,
  outside: Outsidemage,
};

function GalleryCosmeticImages(props) {
  const {
    carFrameData,
    carFrameLayout,
    setSyncData,
    syncData,
    setDotPostData,
    deleteDot,
    setDeleteDot,
    setDeleteItem,
    setSubmitNewDot,
    submitNewDot,
    galleryItemParentId,
  } = props;
  const fileImageInput = useRef(null);
  const [damageImage, setDamageImage] = useState("");
  const [dotData, setDotData] = useState(dotIntialValues);
  const [loadingNewDot, setLoadingNewDot] = useState(false);
  const [activeDotId, setActiveDotId] = useState(null);

  useEffect(() => {
    if (carFrameData.length > 0) {
      let index = carFrameData.length - 1;
      // Gets the last image
      let img_url = carFrameData[index]["attach_image_url"];
      setActiveDotId(carFrameData[index]["id"])
      setDamageImage(img_url);
      setLoadingNewDot(false);
    } else {
      setDamageImage("");
      setLoadingNewDot(false);
    }
  }, [carFrameData]);

  // Toggles dot image. Removes dot if 'Delete' is active
  const handleClick = (id) => {
    if (deleteDot) {
      setDeleteItem({ id: id });
    } else {
      const currentItem = carFrameData.filter((frame) => frame.id === id);
      if (currentItem.length > 0) {
        setDamageImage(currentItem[0]["attach_image_url"]);
        setActiveDotId(id)
      }
    }
  };

  const handleChange = (e) => {
    //let [file] = e.target.files;
    //let file = e.target.files[0];
    //getBase64(file);
    let file = fileImageInput.current.files[0];
    // check if file is selected (for cancel button)
    if (!file) {
      console.log("no file selected");
      return;
    }
    setLoadingNewDot(true);
    let currentDotData = {
      ...dotData,
      attach_image: file,
      parent_id: galleryItemParentId,
    };
    setDotData(currentDotData);
    setDotPostData(currentDotData);
    setSubmitNewDot(true);
    // clear file input
    fileImageInput.current.value = null;
  };

  // Gets and sets 'Clicked' position points
  const handleDotPosition = (e) => {
    let position = getPosition(e);
    let x_coord = ~~position.x;
    let y_coord = ~~position.y;
    // console.log("position x y", x_coord, y_coord);
    setDotData({ ...dotData, x: x_coord, y: y_coord });
    //setSyncData(!syncData);
    // Open file input
    fileImageInput.current.click();
  };

  const getPosition = (e) => {
    let rect = e.target.getBoundingClientRect();
    let x = e.clientX - rect.left;
    let y = e.clientY - rect.top;
    return {
      x,
      y,
    };
  };

  const getBase64 = (file) => {
    if (file) {
      let reader = new FileReader();
      reader.addEventListener("load", (e) => {
        let src = e.target.result;
        //this.dots[this.selectedDot].image = src
        //this.$emit('load', src)
        setDotData({ ...dotData, attach_image: src });
      });
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="row py-4">
      <div className="col-12 col-md-6">
        {loadingNewDot && <div className="loading-spinner-overlay">
          <ClipLoader size={160} color={'#36D7B7'} loading={true} />
        </div>}
        <div
          className="position-relative mx-auto"
          style={{ height: "400px", width: "270px" }}
        >
          <img
            onClick={handleDotPosition}
            src={carFrameImage[carFrameLayout]}
            className="position-absolute start-0 top-0 w-100 full-height "
            style={{ cursor: "cell" }}
          />
          {carFrameData.map((dot, index) => (
            <div
              key={dot.id}
              onClick={() => handleClick(dot.id)}
              className={`dot position-absolute rounded-circle center-item cursor-pointer ${
                dot.id === activeDotId ? 'bg-success' : 'bg-primary'
              } `}
              style={{ left: dot.x, top: dot.y, cursor: deleteDot ? "alias" : "pointer" }}
            ></div>
          ))}
        </div>
      </div>
      <div className="col-12 col-md-6">
        <div
          className="w-100 d-flex align-items-center justify-content-center p-3"
          style={{ height: "400px" }}
        >
          <img
            src={damageImage}
            alt=""
            className="img-fluid d-block rounded "
            style={{ maxHeight: "400px", maxWidth: "100%" }}
          />
        </div>
      </div>
      <div className="col-12">
        <div className="w-100 d-flex ">
          <button onClick={setDeleteDot} className="btn btn-danger px-3 me-3">
            Poista
          </button>
          {deleteDot && (
            <p className="text-danger mb-0">
              Valmis poistamaan. Klikkaa piste, jonka haluat poistaa.
            </p>
          )}
        </div>
      </div>
      <div className="col-12 d-none">
        <input
          ref={fileImageInput}
          onChange={handleChange}
          type="file"
          name="file_image"
          accept="image/jpeg, image/png"
        />
      </div>
    </div>
  );
}

export default GalleryCosmeticImages;
