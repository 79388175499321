import React, {useEffect} from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

export default function HorizontalLabelPositionBelowStepper(props) {
  const [activeStep, setActiveStep] = React.useState(0);

  const steps = props.stepNames;
  if (steps[steps.length - 1] !== "Yhteenveto") {
    steps.push("Yhteenveto");
  } 

  
  const handleStep = (step) => () => {
    if (props.saveResult) {
      props.saveResult(false);
    }
    setActiveStep(step);
    props.changePage(step);
  };

  useEffect(() => {
    setActiveStep(props.step);
  }, [props]);

  const isStepFailed = (step) => {
    let result = false
    if (step <= props.step) {
      result = props.errorSteps.includes(step)
    }
    return result
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label, index) => {
          const labelProps = {};
          if (isStepFailed(index)) {
            labelProps.error = true;
          }
          return (
            <Step key={index}>
              <StepLabel onClick={handleStep(index)} {...labelProps} >
                {label}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
}
