import React, { useState, useEffect } from "react";
import MainGalleryPage from "../components/galleries/MainGalleryPage";

import http from "../services/httpServices";
import env_variable from "../config";

const index_url = "/api/v1/inspection_gallery";

function InspectionGallery(props) {
  const easy_api_url = env_variable.easyad_api_url;
  const [galleries, setGalleries] = useState([]);

  useEffect(() => {
    const getGalleries = async () => {
      const response = await http.get(`${easy_api_url}${index_url}`);
      const { data } = response;
      setGalleries(data);
    };

    // Gets list of Cosmetic remarks
    getGalleries();
  }, []);

  return (
    <main>
      <div className="container pt-3">
        <h1 className="fs-3 text-primary my-3">Virhekuvat</h1>
        <MainGalleryPage galleries={galleries} history={props.history} />
      </div>
    </main>
  );
}

export default InspectionGallery;
