import {StyleSheet} from '@react-pdf/renderer';

const borderColor = 'black'
const pdfStyles = StyleSheet.create({
    page: {
      paddingTop: 35,
      paddingBottom: 65,
      paddingHorizontal: 35,
    }, 
    headerContainer: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      width:'100%',
      marginBottom: 10,
      borderBottomWidth: 1,
      borderBottomColor: 'grey',
    },
    headerLeft: {
      fontSize: 12,
      color: 'grey',
    },
    headerRight: {
      fontSize: 12,
      color: 'grey',
    },
    pageNumber: {
      position: 'absolute',
      fontSize: 12,
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: 'center',
      color: 'grey',
    },
    text: {
      margin: 12,
      fontSize: 14,
      fontFamily: 'Times-Roman'
    },
    groupTableView: {
      marginTop: '8px',
      flexDirection: 'row',
      flexWrap: 'wrap',
      borderWidth: 1,
      borderColor: borderColor,
    },
    groupHeader: {
      width: '100%',
      backgroundColor: '#000000',
      height: 24,
      textAlign: 'left',
      fontStyle: 'bold',
      color: '#ffffff',
      paddingLeft: 5,
      paddingRight: 5,
      fontSize: 16,
      justifyContent: 'center',
    },
    inspectionItemRow: {
      flexDirection: 'row',
      borderBottomColor: borderColor,
      borderBottomWidth: 1,
      alignItems: 'flex-start',
      fontStyle: 'bold',
    },
    inspectionItemName: {
      width: '40%',
      backgroundColor: '#ffffff',
      color: '#000000',
      paddingLeft: 5,
      paddingRight: 5,
      paddingTop: 5,
      paddingBottom: 5,
      fontSize: 12,
      fontStyle: 'bold',
    },
    inspectionItemValue: {
      width: '60%',
      backgroundColor: '#ffffff',
      color: '#000000',
      paddingLeft: 5,
      paddingRight: 5,
      paddingTop: 5,
      paddingBottom: 5,
      fontSize: 12,
      borderLeftColor: borderColor,
      borderLeftWidth: 1,
    },
    imageGroupTableView: {
      marginTop: '8px',
      flexDirection: 'column',
      flexWrap: 'wrap',
      borderWidth: 1,
      borderColor: borderColor,
    },
    imageGroupName: {
      fontSize: 16,
      fontStyle: 'bold',
    },
    imageItemRow: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    imageItemName: {
      color: '#000000',
      paddingLeft: 5,
      paddingRight: 5,
      paddingTop: 5,
      paddingBottom: 5,
      fontSize: 12,
      fontStyle: 'bold',
    },
    image_conatainer: {
      flexDirection: 'column',
      alignItems: "center",
      padding: 5,
      width: '100%'
    },
    attach_image: {
      marginTop: 5,
      maxHeight: 300,
      maxWidth: 450,
      resizeMode: 'contain'
    }
  }); 

  export default pdfStyles;