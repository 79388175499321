export function parseISOString(s) {
  var b = s.split(/\D+/);
  return new Date(Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5], b[6]));
}

export function isoFormatDMY(d) {  
  function pad(n) {return (n<10? '0' :  '') + n}
  return pad(d.getUTCDate()) + '.' + pad(d.getUTCMonth() + 1) + '.' + d.getUTCFullYear() + ' ' + pad(d.getUTCHours()) + ':' +  pad(d.getUTCMinutes());
}

export function isoFormatDate(d) {  
  function pad(n) {return (n<10? '0' :  '') + n}
  return pad(d.getUTCDate()) + '.' + pad(d.getUTCMonth() + 1) + '.' + d.getUTCFullYear();
}


export function dateISOToString1(s) { 
  // 2021-09-02T05:39:11.000Z
  let d = parseISOString(s)
  // yyyy-MM-ddThh:mm 
  function pad(n) {return (n<10? '0' :  '') + n}
  return d.getUTCFullYear() + '-' + pad(d.getUTCMonth() + 1) + '-' + pad(d.getUTCDate()) + 'T' + pad(d.getUTCHours()) + ':' +  pad(d.getUTCMinutes());
}


export function getFormatTimestamp(input_dt) {
  let str_utc = ''
  let str_local = ''
  let dt = new Date();
  if (input_dt && input_dt !== '') {
    dt = new Date(input_dt)
  }

  let d = dt.getDate().toString().padStart(2, '0')
  let m = (dt.getMonth() + 1).toString().padStart(2, '0')
  let y = dt.getFullYear()
  let hour = dt.getHours().toString().padStart(2, '0')
  let min = dt.getMinutes().toString().padStart(2, '0')
  let sec = dt.getSeconds().toString().padStart(2, '0')
  str_local = d + "." + m + "." + y + " " + hour + ":" + min + ":" + sec;
  
  d = dt.getUTCDate().toString().padStart(2, '0')
  m = (dt.getUTCMonth() + 1).toString().padStart(2, '0')
  y = dt.getFullYear()
  hour = dt.getUTCHours().toString().padStart(2, '0')
  min = dt.getUTCMinutes().toString().padStart(2, '0')
  sec = dt.getUTCSeconds().toString().padStart(2, '0')
  str_utc = y + "-" + m + "-" + d + " " + hour + ":" + min + ":" + sec+ ' UTC';

  return [str_utc, str_local]
}