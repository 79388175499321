import React , {useState} from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import {getFormatTimestamp, parseISOString} from '../../services/formatService'

const InputTimestamp = props => {
  const [textValue, setTextValue] = useState({
    id: props.item.inspection_result.data.id,
    value: props.item.inspection_result.data.attributes.value ? getFormatTimestamp(props.item.inspection_result.data.attributes.value)[1] : null,
  });
  let temp_check = false
  if (props.item.inspection_result.data.attributes.value) {
    temp_check = true
  }
  const [isChecked, setIsChecked] = useState(temp_check); 

  
  const handleChange = (e) => {
    let new_timestamp = ''
    if (e.target.checked === true) {
      setIsChecked(true)
      const timestamps = getFormatTimestamp('');
      new_timestamp = timestamps[0];
      setTextValue({ ...textValue, value: timestamps[1] });
    } else {
      setIsChecked(false)
      new_timestamp = '';
      setTextValue({ ...textValue, value: '' });
    }
    props.setFormResults({ ...props.formResults, [props.item.inspection_result.data.id]: new_timestamp });
  };

  return (
    <>
      <FormControlLabel
        control={
          <Switch
            checked={isChecked}
            onChange={handleChange}
            name="inputTimestamp"
            color="primary"
            disabled={props.item.only_self_page}
          />
        }
        label={textValue.value}
      />


    </>
  );
};


export default InputTimestamp;