import React from "react";
import { Link } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa";

const SubNavs = ({ navItem }) => {
  return (
    <ul className="list-unstyled position-absolute start-0 top-100 z-index-to-1050">
      {navItem.map((item, index) => (
        <li key={item.url}>
          <Link className="text-decoration-none" to={item.url}>
            {item.label}
            <span className="fs-10 fw-lighter ms-1">
              <FaArrowRight />
            </span>
          </Link>
        </li>
      ))}
    </ul>
  );
};

export default SubNavs;
