import React, {useEffect} from 'react';
import InspectionStartForm from "./InspectionStartForm";
import {Card} from 'react-bootstrap';
import TabBar from '../navbar/TabBar';

const InspectionStart = props => {

  const pageTitle = props.displayType === "new" ? "Uusi tarkistus" : "Muokkaa tarkistus"
  let stateParams = null
  
  if (props.location.state && props.location.state.id) {
    stateParams = props.location.state
    localStorage.setItem('currentStateParams', JSON.stringify(props.location.state));
  } else if (props.location.state && (props.location.state.displayType === "new")) {
    localStorage.setItem('currentStateParams', null);
    stateParams = props.location.state
  } else {
    stateParams = JSON.parse(localStorage.getItem('currentStateParams'));
  }

  return (
      <main>
        {stateParams.id 
          ? (<TabBar 
            tabNumber={1} 
            plate={stateParams.plate}
            inspectionId={stateParams.id}
            />)
          : (<TabBar 
            tabNumber={1} 
            plate=''
            />)}
        <div className="container mt-100">
              <div className="w-100 rounded-3 p-3">
                <Card className='mt-3'>
                  {true && <Card.Header>{pageTitle}</Card.Header>}
                  <Card.Body>
                    {stateParams.id 
                      ? <InspectionStartForm 
                      id={stateParams.id} 
                      plate={stateParams.plate} 
                      place_name={stateParams.place_name} 
                      phase_name={stateParams.phase_name} 
                      make={stateParams.make} 
                      model={stateParams.model} 
                      vm={stateParams.vm} 
                      mileage={stateParams.mileage}
                      need_notification={stateParams.need_notification}
                      created_by={stateParams.created_by}
                      inspection_location={stateParams.location}
                      info={stateParams.info} />
                      : <InspectionStartForm 
                      plate={stateParams.params_plate} 
                      place={stateParams.params_place} 
                      placeOptions={stateParams.placeOptions} 
                      places={stateParams.places}
                      inspection_location={stateParams.location}/>}
                    </Card.Body>  
                </Card>
              </div>
          </div>
    </main>
  );
  
}

export default InspectionStart;