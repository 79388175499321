import React, { Component } from "react";
import auth from "../services/authService";

class Home extends Component {
  state = {};
  user = auth.getCurrentUser();

  render() {
    return (
      <>
        Etusivu Takastus
      </>

    );
  }
}

export default Home;
