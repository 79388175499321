import React, { useState, useEffect } from "react";
import { useParams, Redirect } from "react-router-dom";
import queryString from "query-string";
import config from "../config";
import auth, {getCurrentToken} from "../services/authService";

const Login = () => {
  const params = useParams();
  const [urlParams] = useState(params);
  const [user, setUser] = useState(auth.getCurrentToken());

  useEffect(() => {
    try {
      const parsedHash = queryString.parse(window.location.hash);
      const { access_token } = parsedHash;
      const currentToken = getCurrentToken();
      if(currentToken)
      {
        console.log("Using existing token")
      }
      else{
        auth.loginWithToken(access_token);
      }
      setUser(auth.getCurrentToken());
    } catch (error) {}
  }, [urlParams]);

  const handleClick = () => {
    window.location = `${config.auth_api_url}${config.auth_api_path}?client_id=${config.client_id}&scope=${config.client_scope}&response_type=${config.response_type}&redirect_uri=${config.redirect_url}`;
  };

  //const pathname = routeLocation.state.from.pathname;
  //const pathState = pathname ? pathname : "/";

  return (
    <>
      {user ? (
        <Redirect to="/admin" />
      ) : (
        <main>
          <div className="container">
            <div className="max-width-460 mx-auto px-3 py-5 my-5 shadow rounded-10">
              <h1 className="fs-3 mb-5 text-center">Kirjaudu</h1>
              <div className="d-grid my-5">
                <button
                  onClick={handleClick}
                  className="btn btn-primary text-white rounded-pill outline-none no-shadow-btn"
                >
                  Jatka
                </button>
              </div>
            </div>
          </div>
        </main>
      )}
    </>
  );
};

export default Login;
