import React, {useEffect, useState} from 'react';
import InputState from '../inputs/InputState';
import InputDate from '../inputs/InputDate';
import InputDateTime from '../inputs/InputDateTime';
import InputTimestamp from '../inputs/InputTimestamp';
import InputText from '../inputs/InputText';
import InputInteger from '../inputs/InputInteger';
import InputRadio from '../inputs/InputRadio';
import InputCheckbox from '../inputs/InputCheckbox';
import InputAttachFile from '../inputs/InputAttachFile';
import DropZoneModal from '../inputs/DropZoneModal';
import Modal from 'react-modal';
import InspectionModalViewImages from "./InspectionModalViewImages";
import {FaEye, FaAsterisk} from "react-icons/fa";
import {getInspectionResult} from "../../services/inspectionService"

const customStyles = {
  content: {
    size: 'lg',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '85%',
    overlay: {zIndex: 1060}
  },
};

const InspectionItem = props => {
  const itemDetail = props.item.attributes
  const itemResult =   itemDetail.inspection_result.data.attributes
  const input_type =  itemResult.kind.replace('_only','').replace('_images','')
  const has_images = itemResult.kind.substr(itemResult.kind.length - 6) === 'images';
  const [modalIsOpen, setIsOpen] = useState(false);
  const [imageAmount, setImageAmount] = useState(0);
  const [isCountImage, setIsCountImage] = useState(
    ['both','image'].includes(props.item.attributes.inspection_result.data.attributes.forced)
  );

  useEffect(() => {
    if (isCountImage === true) {
      countImages(props.item.attributes.inspection_result.data.id)
    }
  }, [isCountImage]);


  const countImages = (id) => {
    getInspectionResult(id).then((result)=>{
      if (result.data && result.data.data.attributes.attach_image_urls) {
        const count = result.data.data.attributes.attach_image_urls.length 
        setImageAmount(count);
        console.log("count image:", count);
        setIsCountImage(false);
        if (['both','image'].includes(props.item.attributes.inspection_result.data.attributes.forced)) {
           const imgResultId = 'img_' + props.item.attributes.inspection_result.data.id
           if (count > 0) {
            console.log('Validate image '+ props.item.attributes.name + ': passed');
            props.setMandatoryImages(props.mandatoryImages.filter(item => item !== imgResultId));
           } else {           
            console.log('Validate image '+ props.item.attributes.name + ': failed'); 
            props.setMandatoryImages([...new Set([...props.mandatoryImages, imgResultId])])
           }
        }
        
      } 
    })
  }

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
  }

  function closeModal() {
    setIsOpen(false);
    setIsCountImage(true);
  }

  const components = {
    state: InputState,
    date: InputDate,
    time: InputDateTime,
    timestamp: InputTimestamp,
    integer: InputInteger,
    decimal: InputInteger,
    text: InputText,
    checkbox: InputCheckbox,
    radio: InputRadio,
  };


  function CustomInput(componentName, props, itemDetail) {
      // Correct! JSX type can be a capitalized variable.
      const CustomInput = components[componentName];
      return (
        <CustomInput 
          item={itemDetail} 
          formResults={props.formResults} 
          setFormResults={props.setFormResults} 
          inspectionStartInfo={props.inspectionStartInfo} 
          mandatoryInputs={props.mandatoryInputs}
          setIsInputChange={props.setIsInputChange}
        />
      );
  }


  return (
    <div className="form-group row">
        <div className="col-lg-4 col-md-4 inspection-title">
          {itemDetail.name}
          {['both','input'].includes(itemDetail.inspection_result.data.attributes.forced) 
	  && <span style={{color: 'red'}}>*</span>}
          {/* {itemDetail.inspection_result.data.attributes.id}   {itemDetail.inspection_result.data.attributes.kind} */}
        </div>
        <div className="col-lg-5 col-md-6">
          {CustomInput(input_type, props, itemDetail)}
          {/* {input_type==="state" && <InputState item={itemDetail} formResults={props.formResults} setFormResults={props.setFormResults} />}
          {input_type==="date" && <InputDate item={itemDetail} formResults={props.formResults} setFormResults={props.setFormResults}  />}
          {input_type==="time" && <InputDateTime item={itemDetail} formResults={props.formResults} setFormResults={props.setFormResults}  />}
          {input_type==="timestamp" && <InputTimestamp item={itemDetail} formResults={props.formResults} setFormResults={props.setFormResults}  />}
          {input_type==="integer" && <InputInteger item={itemDetail} formResults={props.formResults} setFormResults={props.setFormResults} inspectionStartInfo={props.inspectionStartInfo} />}
          {input_type==="decimal" && <InputInteger item={itemDetail} formResults={props.formResults} setFormResults={props.setFormResults} inspectionStartInfo={props.inspectionStartInfo} />}
          {input_type==="text" && <InputText item={itemDetail} formResults={props.formResults} setFormResults={props.setFormResults} inspectionStartInfo={props.inspectionStartInfo} />}
          {input_type==="checkbox" && <InputCheckbox item={itemDetail} formResults={props.formResults} setFormResults={props.setFormResults} />}
          {input_type==="radio" && <InputRadio item={itemDetail} formResults={props.formResults} setFormResults={props.setFormResults} />} */}
        </div>
        <div className="col-lg-3 col-md-2">

          {has_images && <InputAttachFile item={itemDetail}  closeModal={closeModal}  />}
          {has_images && (<div>
            <button
              type="button"
              className="btn btn-sm"
              // onClick={()=> window.open(`http://localhost:3011/inspections/view_images/${props.item.inspection_result.data.id}`, 'view_image', 'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no, height=600, width=900')}
              onClick={openModal}
            >
              <span className="fs-12 me-1" >
                  <FaEye />
              </span>
              <span className="me-1" >
                Näyttää kuvat
                {['both','image'].includes(props.item.attributes.inspection_result.data.attributes.forced) 
                  && (<span >({imageAmount})</span>)}
              </span>
            </button>  
          <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel={`Example Modal ${props.inspectionId}`}
            ariaHideApp={false}
            >
            <InspectionModalViewImages 
            closeModal={closeModal} 
            name={itemDetail.name} 
            id={itemResult.id} 
            setIsCountImage={setIsCountImage}
            />
          </Modal></div>)}
          {has_images && <DropZoneModal item={itemDetail}  closeModal={closeModal} />}

        </div>
    </div>
  );
};


export default InspectionItem;